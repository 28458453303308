import { SiGooglemeet, SiZoom } from "react-icons/si";
import {domain} from '../../../api/Consts'

export default function BatchSlotCard(props) {
  return (
    <>
      <div className="flex flex-row bg-white rounded-lg shadow-md p-5 w-4/5 my-2">
        <div className="w-2/5 mx-2">
          <img
            className="h-56 w-full object-cover object-center shadow-md"
            src={
              props.courseImage
                ? props.courseImage
                : "https://via.placeholder.com/350x150.png?text=No+Course+Image"
            }
            alt="course-img"
          ></img>
        </div>

        <div className="w-3/5">
          <p>
            <span className="font-bold">Batch Name: </span>
            {props.batchName}
          </p>
          <p>
            <span className="font-bold">Course Details: </span>
            {props.coureName}
          </p>
          <p>
            <span className="font-bold">Slot Date and Time: </span>
            {props.slotDate} {props.slotDays}
          </p>
          <p>
            <span className="font-bold">Number of Enrolled Trainees: </span> {props.enrolledTrainees}
          </p>
          <div className="flex flex-row">
            <a
              href={`${domain}/api/moodle?access_token=${props.accessToken}&course_id=${props.courseId}&`}
              className="bg-yellow-100 text-gray-600 cursor-pointer no-underline p-2 rounded-md font-semibold mx-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to Course
            </a>
            {props.platform === "hangoutsMeet" ? (
              <a
                href={props.meetingUrl ? props.meetingUrl : "#"}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-row no-underline cursor-pointer items-center bg-light-orange hover:bg-opacity-90 text-white font-semibold mx-2 rounded-md p-2"
              >
                <SiGooglemeet size={20} className="mx-1" />
                Join Google Meet
              </a>
            ) : (
              <a
                href={props.meetingUrl ? props.meetingUrl : "#"}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-row no-underline cursor-pointer items-center bg-light-orange hover:bg-opacity-90 text-white font-semibold mx-2 rounded-md p-2"
              >
                <SiZoom size={24} className="mx-1" />
                Join Zoom Meet
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
