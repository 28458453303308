import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { FETCH_POSSIBLE_TECHNICAL_QUALIFICATION } from "../GraphQl/Queries/Qualification";
import {
  rNumField,
  rPhoneField,
  rFileField,
  rStringField,
  addressField,
} from "../components/Form/presets";
import {
  CONTACT_PERSON_NAMES,
  CONTACT_PERSON_TYPE,
  MARKING_TYPE,
  MARKING_TYPE_NAMES,
  TECHNICAL_QUALIFICATION_NAMES,
  TECHNICAL_QUALIFICATION_TYPE,
} from "../api/Consts";

import * as yup from "yup";
function QualificationFormBuilder(values) {
  const CORRESPONDING_POSSIBLE_QUALIFICATIONS = useQuery(
    FETCH_POSSIBLE_TECHNICAL_QUALIFICATION
  );
  const [schema, setSchema] = useState();

  const getQualName = (qualId) => {
    for (let type in CORRESPONDING_POSSIBLE_QUALIFICATIONS?.data || {}) {
      for (let qual of CORRESPONDING_POSSIBLE_QUALIFICATIONS?.data?.[type]) {
        if (qualId == qual.id)
          return qual.name + (qual.qp_code ? " (" + qual.qp_code + ")" : "");
      }
    }
  };

  const createSchema = () => {
    const schema = {
      qualifications: {
        type: "object",
        repeat: true,
        insertable: true,
        insertableProps: {
          button: {
            label: "Add qualification",
            className: "button",
          },
        },
        collapsible: true,
        collapsibleProps: {
          title: (item) => getQualName(item.qualification_id),
          emptyTitle: "Name of Course",
        },
        fields: ({ value: qualification }) => {
          return {
            id: { type: "hidden", schema: yup.string().nullable() },
            qualification_type: rStringField("Techincal Qualification type", {
              type: "select",
              options: Object.values(TECHNICAL_QUALIFICATION_TYPE).map(
                (techQualType) => ({
                  value: techQualType,
                  label: TECHNICAL_QUALIFICATION_NAMES[techQualType],
                })
              ),
            }),
            qualification_id: rStringField("Qualification name", {
              type: "select",
              options: CORRESPONDING_POSSIBLE_QUALIFICATIONS?.data?.[
                qualification?.qualification_type
              ]?.map((qualType) => ({
                value: qualType.id,
                label:
                  qualType.name +
                  (qualType.qp_code ? " (" + qualType.qp_code + ")" : ""),
              })),
            }),
            document_proof: { type: "hidden", schema: yup.object().nullable() },
            marking_type: rStringField("Marking system", {
              type: "select",
              options: Object.values(MARKING_TYPE).map((mType) => ({
                value: mType,
                label: MARKING_TYPE_NAMES[mType],
              })),
            }),
            marks: rStringField("Percentage / Grade"),
            institution_name: rStringField("Name of the Institute"),
            doc: rFileField("Document Proof", "Upload", {
              fileFieldProps: { accept: ".pdf" },
            }),
            address: addressField("Address of the institute"),
            contact_person: {
              label: "Institute Contact Person",
              type: "object",
              fields: {
                contact_person_type: rStringField("Type", {
                  type: "select",
                  options: Object.values(CONTACT_PERSON_TYPE).map((type) => ({
                    label: CONTACT_PERSON_NAMES[type],
                    value: type,
                  })),
                }),
                contact_person_name: rStringField("Name"),
                contact_person_mobile_number: rPhoneField("Mobile number"),
              },
            },
          };
        },
      },
      dummy_file: {
        type: "file",
        className: "hidden",
        schema: yup.array().of(yup.object().nullable()).nullable(),
      },
    };
    setSchema(schema);
  };

  useEffect(
    () => createSchema(),
    [values, CORRESPONDING_POSSIBLE_QUALIFICATIONS]
  );

  return schema;
}

export default QualificationFormBuilder;
