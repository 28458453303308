import axios from "axios";
import { getLoginCookie, getJwtToken } from "../utils/Auth";
import { domain } from "./Consts";

function getHeaders() {
  var headers = {};
  if (getLoginCookie()) {
    headers = {
      ...headers,
      "access-token": getLoginCookie(),
      "jwt-token": getJwtToken(),
    };
  }

  return headers;
}

function api() {
  const Axios = axios.create({
    baseURL: domain + "/api",
    headers: getHeaders(),
  });

  return Axios;
}
// export default Axios;

export async function uploadFile(file) {
  console.log("file upload");
  const { name: image_name } = file;
  const reader = new FileReader();
  reader.readAsDataURL(file);
  const result = await new Promise((res) => {
    reader.onloadend = () => {
      res(reader.result);
    };
  });
  return api()
    .post("/upload_file", {
      image_data: result,
      image_name,
    })
    .then(({ data }) => data);
}

export async function getFileUrl(url) {
  return api()
    .post("/get_file", { data_url: url })
    .then(({ data }) => data);
}

export default api;
