import { client } from "../App";
import {
  GET_COURSE_SECTION,
  CHECK_USER_COMPLETION_BY_SECTION_TOPIC,
  GET_MODULE_RESTRICTION,
  GET_RESTRICTION_COMPLETION_STATUS,
  GET_COURSE_MAPPING_DATA,
  CHECK_MOD_TYPE,
  GET_USER_QUIZ_ATTEMPT,
  GET_USER_QUIZ_QUESTION_ATTEMPT,
  GET_COURSE_CERTIFICATE_DATA,
  GET_COURSE_COMPLETION_TOPICS,
  GET_USER_COURSE_COMPLETED_TOPICS,
  GET_USERS_BATCH_ATTENDANCE,
  GET_BATCH_ATTENDANCE,
} from "../GraphQl/Queries/Courses";
import {
  UPDATE_BATCH_ATTENDANCES,
  INSERT_BATCH_USER_ATTENDANCE,
} from "../GraphQl/Mutations/Batch";

class batchUsers {
  async getCourseSection(courseid) {
    const result = await client
      .query({
        query: GET_COURSE_SECTION,
        variables: { course_id: courseid },
      })
      .then((res) => res.data.courses_course_section);

    return result;
  }
  async checkUserCompletionBySection(section_id, user_id, mapping_id) {
    const result = await client
      .query({
        query: CHECK_USER_COMPLETION_BY_SECTION_TOPIC,
        variables: {
          section_id: section_id,
          user_id: user_id,
          mapping_id: mapping_id,
        },
      })
      .then((res) =>
        res.data.courses_course_module_completion.length > 0 ? true : false
      );

    return result;
  }

  async getModuleRestriction(mapping_id) {
    const result = await client
      .query({
        query: GET_MODULE_RESTRICTION,
        variables: { mapping_id: mapping_id },
      })
      .then(({ data }) =>
        data.courses_module_completion_resrtriction
          ? data.courses_module_completion_resrtriction[0]
          : []
      );

    return result;
  }

  async getModuleRestrictionStatus(user_id, restriction_ids) {
    var restIds = restriction_ids.split(",").map(function (strVale) {
      return Number(strVale);
    });

    const result = await client
      .query({
        query: GET_RESTRICTION_COMPLETION_STATUS,
        variables: {
          user_id: user_id,
          mapping_id: restIds,
        },
      })
      .then(({ data }) =>
        data.courses_course_module_completion
          ? data.courses_course_module_completion.map((e) => e.mapping_id)
          : []
      );

    return result;
  }
  async getCourseMapping(id) {
    const result = await client
      .query({
        query: GET_COURSE_MAPPING_DATA,
        variables: {
          id: id,
        },
      })
      .then((res) => res.data.courses_course_module_mapping[0]);

    return result;
  }

  async checkModType(mod_id) {
    const result = await client
      .query({
        query: CHECK_MOD_TYPE,
        variables: {
          mod_id: mod_id,
        },
      })
      .then((res) => res.data.courses_course_module_mapping[0].Module.type);

    return result;
  }

  async getUserQuizAttempt(mapping_id, user_id) {
    const result = await client
      .query({
        query: GET_USER_QUIZ_ATTEMPT,
        variables: {
          mapping_id: mapping_id,
          user_id: user_id,
        },
      })
      .then((res) => res.data.courses_user_course_quiz_attempt);

    return result;
  }

  async getUserQuizQuestionAttempt(attempt_quiz_id) {
    const result = await client
      .query({
        query: GET_USER_QUIZ_QUESTION_ATTEMPT,
        variables: {
          attempt_quiz_id: attempt_quiz_id,
        },
      })
      .then((res) => res.data.courses_user_course_question_attemept);

    return result;
  }

  async getCourseCertificateData(mapping_id) {
    const result = await client
      .query({
        query: GET_COURSE_CERTIFICATE_DATA,
        variables: {
          mapping_id: mapping_id,
        },
      })
      .then(({ data }) => data.courses_course_template_certificates_data[0]);

    return result;
  }

  async getCourseCompletionTopics(courseid) {
    const result = await client
      .query({
        query: GET_COURSE_COMPLETION_TOPICS,
        variables: { course_id: courseid },
      })
      .then((res) => res.data?.courses_course_completion_criteria);

    return result;
  }

  async GetUserCourseCompletedTopics(course_id, user_id) {
    const result = await client
      .query({
        query: GET_USER_COURSE_COMPLETED_TOPICS,
        variables: {
          course_id: course_id,
          user_id: user_id,
        },
      })
      .then(({ data }) => data.courses_course_module_completion);

    return result;
  }

  async getUserBatchAttendance(slot_id, user_id) {
    const result = await client
      .query({
        query: GET_USERS_BATCH_ATTENDANCE,
        variables: {
          slot_id: slot_id,
          user_id: user_id,
        },
      })
      .then((res) => res.data?.courses_batch_trainee_attendences[0]);

    return result;
  }

  getBatchAttendance(batch_id) {
    return client
      .query({
        query: GET_BATCH_ATTENDANCE,
        variables: {
          batch_id,
        },
        fetchPolicy: "network-only",
      })
      .then((res) => res.data?.courses_batch_trainee_attendences);
  }

  async updateUserAttendance(arrayData) {
    const result = await client
      .mutate({
        mutation: UPDATE_BATCH_ATTENDANCES,
        variables: arrayData,
      })
      .then((res) => res.data?.update_courses_batch_trainee_attendences);

    return result;
  }

  async insertUserAttendance(arrayData) {
    const result = await client
      .mutate({
        mutation: INSERT_BATCH_USER_ATTENDANCE,
        variables: arrayData,
      })
      .then((res) => res.data?.insert_courses_batch_trainee_attendences);

    return result;
  }
}

const batchUsersService = new batchUsers();

export default batchUsersService;
