import { gql } from "@apollo/client";

export const GET_BATCH_SCHEDULES = gql`
  query course_batches($instructor_id: Int) {
    courses_course_batches(
      where: {
        _or: [
          { instructor_id: { _eq: $instructor_id } }
          { batch_slots: { instructor_id: { _eq: $instructor_id } } }
        ]
      }
      order_by: { slot_start_time: asc, from_date: asc, slot_end_time: asc }
    ) {
      batch_name
      instructor_id
      id
      from_time
      course_id
      max_learners
      meeting_link
      min_learners
      project_id
      partner_id
      platform
      repeat_end_time
      slots_days
      to_time
      from_date
      to_date
      slot_start_time
      slot_end_time
      is_demo
      type
      batch_slots(order_by: { id: asc }) {
        id
        slot_date
        meeting_link
        platform
        endto_date
        instructor_id
        slots_days
      }
      userEnrollmentBatchArray {
        user_id
        user {
          id
          email
          name
        }
      }
      course {
        full_name
      }
    }
  }
`;
