import { gql } from "@apollo/client";

export const GET_INSTRUCTORS = gql`
  query getInstructors {
    courses_instructor {
      activation_end_date
      activation_start_date
      active
      created_at
      email
      id
      mobile_number
      name
      updated_at
    }
  }
`;

export const GET_INSTRUCTOR_BY_ID = gql`
  query ($instructorId: bigint!) {
    courses_instructor_by_pk(id: $instructorId) {
      activation_end_date
      activation_start_date
      active
      created_at
      email
      id
      mobile_number
      name
      updated_at
      has_allowed_location
    }
  }
`;
