import { gql } from "@apollo/client";

export const COURSES_QUERY = gql`
  query fetchCourses {
    courses_course {
      course_category_id
      created_at
      description
      end_date
      full_name
      id
      identifier
      is_moodle_course
      moodle_config_id
      moodle_course_url
      publish
      start_date
      updated_at
      moodle_config {
        id
        moodle_sp_name
      }
      course_category {
        id
        name
      }
    }
  }
`;

export const GET_COURSES_PAGINATION = gql`
  query fetchCourses($per_page: Int, $page: Int) {
    courses_course(limit: $per_page, offset: $page) {
      course_category_id
      created_at
      description
      end_date
      full_name
      id
      identifier
      is_moodle_course
      moodle_config_id
      moodle_course_url
      publish
      start_date
      updated_at
      moodle_config {
        id
        moodle_sp_name
      }
      course_category {
        id
        name
      }
    }
  }
`;

export const GET_COURSE_BY_ID = gql`
  query fetchCourseById($id: bigint) {
    courses_course(where: { id: { _eq: $id } }) {
      cost
      course_category {
        id
        name
      }
      course_category_id
      created_at
      description
      discount
      duration
      end_date
      full_name
      id
      identifier
      image_url
      is_live_course
      is_moodle_course
      moodle_config {
        id
        moodle_sp_name
      }
      moodle_config_id
      moodle_course_id
      moodle_course_url
      nsql_level
      publish
      short_name
      start_date
      updated_at
    }
  }
`;

export const COURSE_CATEGORY_QUERY = gql`
  query fetchCourseCategories {
    courses_course_categories {
      created_at
      description
      id
      name
      slug
      updated_at
      visible
    }
  }
`;

export const MOODLE_CONFIG_QUERY = gql`
  query fetchMoodleConfig {
    courses_moodle_config {
      moodle_server_acs_url
      moodle_sp_client_id
      moodle_sp_name
      moodle_sp_redirect_url
      updated_at
      id
    }
  }
`;
export const GET_MOODLE_CONFIG_BY_ID = gql`
  query fetchMoodleConfigById($id: bigint) {
    courses_moodle_config(where: { id: { _eq: $id } }) {
      moodle_server_acs_url
      moodle_sp_client_id
      moodle_sp_name
      moodle_sp_redirect_url
      moodle_sp_secret_key
    }
  }
`;
export const CATEGORY_MOODLE_CONFIG_QUERY = gql`
  query fetchMoodleAndCourseCategories {
    courses_course_categories {
      id
      name
    }
    courses_moodle_config {
      moodle_sp_name
      id
    }
  }
`;

export const GET_COURSE_SECTION = gql`
  query getcoursesection($course_id: Int) {
    courses_course_section(
      where: { course_id: { _eq: $course_id } }
      order_by: { order: asc }
    ) {
      name
      id
      course_id
      coursesec {
        name
        description
        mod_id
        id
        publish_url
        section_id
        completion_percentage
        completion_criteria
        Module {
          name
          type
        }
      }
    }
  }
`;

export const CHECK_USER_COMPLETION_BY_SECTION_TOPIC = gql`
  query checkUserCompletionBySection(
    $user_id: Int
    $section_id: Int
    $mapping_id: Int
  ) {
    courses_course_module_completion(
      where: {
        user_id: { _eq: $user_id }
        section_id: { _eq: $section_id }
        mapping_id: { _eq: $mapping_id }
      }
    ) {
      user_id
    }
  }
`;

export const GET_MODULE_RESTRICTION = gql`
  query ModuleRestriction($mapping_id: Int) {
    courses_module_completion_resrtriction(
      where: { mapping_id: { _eq: $mapping_id } }
    ) {
      restriction_ids
    }
  }
`;

export const GET_RESTRICTION_COMPLETION_STATUS = gql`
  query GetRestrictionCompletionStatus($mapping_id: [Int!], $user_id: Int) {
    courses_course_module_completion(
      where: { user_id: { _eq: $user_id }, mapping_id: { _in: $mapping_id } }
    ) {
      id
      mapping_id
      completion_status
    }
  }
`;

export const GET_COURSE_MAPPING_DATA = gql`
  query MyQuery($id: Int) {
    courses_course_module_mapping(where: { id: { _eq: $id } }) {
      course_id
      mod_id
      section_id
      completion_criteria
      completion_percentage
      name
      require_webcam
      scorm_track_data {
        lesson_status
        mapping_id
        mod_id
        score_max
        score_min
        score_raw
        session_time
        success_status
        suspend_data
        user_id
        attempt_id
        scorm_attempt {
          attempt_completed
          attempt_end
          attempt_number
          attempt_start
          course_id
          id
          mapping_id
          user_id
        }
      }
      scorm_completion_criteria {
        passed
        completed
        scorm_version
        mapping_id
      }
      publish_url
      maxattempt
      moodle_published
      attemtsgrade
      course_module {
        full_name
      }
      enable_timer
      timelimit_number
    }
  }
`;

export const CHECK_MOD_TYPE = gql`
  query checkModType($mod_id: Int) {
    courses_course_module_mapping(where: { mod_id: { _eq: $mod_id } }) {
      Module {
        type
      }
    }
  }
`;

export const GET_USER_QUIZ_ATTEMPT = gql`
  query MyQuery($mapping_id: Int, $user_id: Int) {
    courses_user_course_quiz_attempt(
      where: { mapping_id: { _eq: $mapping_id }, user_id: { _eq: $user_id } }
    ) {
      attempt_start
      attempt_percentage
      attempt_number
      attempt_end
      attempt_completed
      result
      total_score
      max_marks
      id
    }
  }
`;

export const GET_USER_QUIZ_QUESTION_ATTEMPT = gql`
  query MyQuery($attempt_quiz_id: Int) {
    courses_user_course_question_attemept(
      where: { attempt_quiz_id: { _eq: $attempt_quiz_id } }
    ) {
      marks
      question_correct_answer
      response
      quiestionsobject {
        question_text
      }
      coursemapping {
        name
      }
    }
  }
`;

export const GET_COURSE_CERTIFICATE_DATA = gql`
  query getPreviewCertData($mapping_id: Int) {
    courses_course_template_certificates_data(
      where: { mapping_id: { _eq: $mapping_id } }
    ) {
      bg_image_url
      course_name
      course_name_size
      course_x_axis
      course_y_axis
      date_size
      date_x_axis
      date_y_axis
      enroll_size
      enroll_x_axis
      enroll_y_axis
      enrollment_id
      id
      issued_date
      mapping_id
      nsqf
      nsqf_size
      nsqf_x_axis
      nsqf_y_axis
      partner_name
      partner_size
      partner_x_axis
      partner_y_axis
      per_size
      per_x_axis
      per_y_axis
      percentage_grade
      project_name
      project_size
      project_x_axis
      project_y_axis
      qr_size
      qr_x_axis
      qr_y_axis
      user_name
      user_text_size
      user_x_axis
      user_y_axis
      grade
      grade_x_axis
      grade_y_axis
      grade_size
      enrollment_id_element
      course_name_element
      grade_element
      issued_date_element
      nsqf_element
      partner_name_element
      percentage_element
      project_element
      qr_element
      user_name_element
    }
  }
`;

export const GET_COURSE_COMPLETION_TOPICS = gql`
  query GetCourseCompletionTopics($course_id: Int) {
    courses_course_completion_criteria(
      where: { course_id: { _eq: $course_id } }
    ) {
      mapping_id
      id
    }
  }
`;

export const GET_USER_COURSE_COMPLETED_TOPICS = gql`
  query GetUserCompletedTopics($course_id: Int, $user_id: Int) {
    courses_course_module_completion(
      where: { course_id: { _eq: $course_id }, user_id: { _eq: $user_id } }
    ) {
      mapping_id
      course_id
    }
  }
`;

export const GET_USERS_BATCH_ATTENDANCE = gql`
  query MyQuery($slot_id: Int, $user_id: Int) {
    courses_batch_trainee_attendences(
      where: { slot_id: { _eq: $slot_id }, user_id: { _eq: $user_id } }
    ) {
      id
      slot_id
      user_id
      attendance_status
    }
  }
`;

export const GET_BATCH_ATTENDANCE = gql`
  query ($batch_id: Int) {
    courses_batch_trainee_attendences(where: { batch_id: { _eq: $batch_id } }) {
      id
      slot_id
      user_id
      attendance_status
      slot {
        id
        slot_date
      }
      user {
        id
        name
        email
      }
    }
  }
`;
