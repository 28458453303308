import { gql } from "@apollo/client";
import {
  ADDRESS_COLUMNS,
  IDENTITY_DOCUMENT_COLUMNS,
  MEMBER_COLUMNNS,
  QUALIFICATIONS_COLUMNS,
  TAG_COLUMNS,
  USER_COLUMNS,
  WORK_DETAILS_COLUMNS,
  LANGUAGES_COLUMNS,
  SKILLS_COLUMNS,
} from "../Queries/AccountDetails";

// User
export const UPDATE_USER_MUTATION = gql`mutation ($id: bigint, $values: courses_users_set_input) {
  update_courses_users(
    where: {
      id: {
        _eq: $id
      }
    },
    _set: $values
  ) {
    returning {
      ${USER_COLUMNS}
    }
  }
}`;

// Address
export const INSERT_USER_ADDRESS_MUTATION = gql`mutation ($values: [courses_user_address_insert_input!]!) {
  insert_courses_user_address(objects: $values) {
    returning {
      ${ADDRESS_COLUMNS}
    }
  }
}`;

export const ATTACH_ADDRESS_TO_USER_MUTATION = gql`
  mutation ($addressId: bigint, $userId: bigint) {
    update_courses_users(
      where: { id: { _eq: $userId } }
      _set: { address_id: $addressId }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_USER_ADDRESS_MUTATION = gql`mutation ($id: bigint, $values: courses_user_address_set_input) {
  update_courses_user_address(
    where: {
      id: {
        _eq: $id
      }
    },
    _set: $values
  ) {
    returning {
      ${ADDRESS_COLUMNS}
    }
  }
}`;

export const DELETE_USER_ADDRESS_MUTATION = gql`
  mutation ($id: bigint) {
    delete_courses_user_address(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

// Identity Documents
export const INSERT_IDENTITY_DOCUMENT_MUTATION = gql`mutation($values: [courses_user_identity_documents_insert_input!]!) {
  insert_courses_user_identity_documents(objects: $values) {
    returning {
      ${IDENTITY_DOCUMENT_COLUMNS}
    }
  }
}`;

export const UPDATE_IDENTITY_DOCUMENT_MUTATION = gql`mutation($id: bigint, $values: courses_user_identity_documents_set_input) {
  update_courses_user_identity_documents(
    where: {
      id: { _eq: $id }
    },
    _set: $values
  ) {
    returning {
      ${IDENTITY_DOCUMENT_COLUMNS}
    }
  }
}`;

export const DELETE_IDENTITY_DOCUMENT_MUTATION = gql`
  mutation ($ids: [bigint]) {
    delete_courses_user_identity_documents(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;

// Qualification
export const INSERT_ACADEMIC_QUALIFICATIONS = gql`
  mutation ($values: [courses_user_academic_qualifications_insert_input!]!) {
    insert_courses_user_academic_qualifications (objects: $values) {
      returning {
        ${QUALIFICATIONS_COLUMNS}
      }
    }
  }
`;

export const UPDATE_ACADEMIC_QUALIFICATIONS = gql`
  mutation ($qualificationId: bigint, $values: courses_user_academic_qualifications_set_input) {
    update_courses_user_academic_qualifications (
      where: {
        id: { _eq: $qualificationId }
      },
      _set: $values
     ) {
      returning {
        ${QUALIFICATIONS_COLUMNS}
      }
    }
  }
`;

export const DELETE_ACADEMIC_QUALIFICATION_MUTATION = gql`
  mutation ($id: bigint) {
    delete_courses_user_academic_qualifications(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const ATTACH_ADDRESS_TO_QUALIFICATION_MUTATION = gql`mutation ($qualId: bigint, $addressId: bigint) {
  update_courses_user_academic_qualifications (
    where: {
      id: { _eq: $qualId }
    },
    _set: {
      institution_address_id: $addressId
    }
  ) {
    returning {
      ${QUALIFICATIONS_COLUMNS}
    }
  }
}`;

export const ATTACH_IDENTITY_DOCUMENT_TO_QUALIFICATION_MUTATION = gql`mutation ($qualId: bigint, $docId: bigint) {
  update_courses_user_academic_qualifications (
    where: {
      id: { _eq: $qualId}
    },
    _set: {
      document_proof_id: $docId
    }
  ) {
    returning {
      ${QUALIFICATIONS_COLUMNS}
    }
  }
}`;

// Skill
export const INSERT_SKILL = gql`
  mutation ($values: [courses_user_skills_details_insert_input!]!) {
    insert_courses_user_skills_details (objects: $values) {
      returning {
        ${SKILLS_COLUMNS}
      }
    }
  }
`;

export const DELETE_SKILL_MUTATION = gql`
  mutation ($id: uuid) {
    delete_courses_user_skills_details(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

// Language
export const INSERT_LANGUAGE = gql`
  mutation ($values: [courses_user_language_details_insert_input!]!) {
    insert_courses_user_language_details (objects: $values) {
      returning {
        ${LANGUAGES_COLUMNS}
      }
    }
  }
`;

export const UPDATE_LANGUAGE = gql`
  mutation ($languageId: uuid, $values: courses_user_language_details_set_input) {
    update_courses_user_language_details (
      where: {
        id: { _eq: $languageId }
      },
      _set: $values
     ) {
      returning {
        ${LANGUAGES_COLUMNS}
      }
    }
  }
`;

export const DELETE_LANGUAGE_MUTATION = gql`
  mutation ($id: uuid) {
    delete_courses_user_language_details(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

// Family members
export const INSERT_MEMBER_MUTATION = gql`mutation ($values: [courses_user_family_details_insert_input!]!) {
  insert_courses_user_family_details (objects: $values) {
    returning {
      ${MEMBER_COLUMNNS}
    }
  }
}`;

export const UPDATE_MEMBER_MUTATION = gql`mutation ($id: bigint, $values: courses_user_family_details_set_input) {
  update_courses_user_family_details (
    where: {
      id: { _eq: $id }
    },
    _set: $values
  ) {
    returning {
      ${MEMBER_COLUMNNS}
    }
  }
}`;

export const DELETE_MEMBER_MUTATION = gql`
  mutation ($memberIds: [bigint]) {
    delete_courses_user_family_details(where: { id: { _in: $memberIds } }) {
      affected_rows
    }
  }
`;

// Work details

export const INSERT_WORK_DETAILS = gql`
  mutation ($objects: [courses_user_work_details_insert_input!]!) {
    insert_courses_user_work_details (objects: $objects) {
      returning {
        ${WORK_DETAILS_COLUMNS}
      }
    }
  }
`;

export const UPDATE_WORK_DETAILS = gql`
  mutation ($workId: bigint, $changes: courses_user_work_details_set_input) {
    update_courses_user_work_details (
      where: {
        id: { _eq: $workId }
      },
      _set: $changes
    ) {
      returning {
        ${WORK_DETAILS_COLUMNS}
      }
    }
  }
`;

export const DELETE_WORK_DETAILS = gql`
  mutation ($ids: [bigint]) {
    delete_courses_user_work_details(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;

// Tags
export const INSERT_USER_TAG = gql`
  mutation ($values: [courses_user_tags_insert_input!]!) {
    insert_courses_user_tags (objects: $values) {
      returning {
        ${TAG_COLUMNS}
      }
    }
  }
`;

export const UPDATE_USER_TAG = gql`
  mutation ($tagId: bigint, $values: courses_user_tags_set_input) {
    update_courses_user_tags (
      where: {
        id: { _eq: $tagId }
      },
      _set: $values
    ) {
      returning {
        ${TAG_COLUMNS}
      }
    }
  }
`;
