import Container from "../../components/Container";
import { Form } from "skillstrainer-resource-library";
import { TraineeFormBuilder } from "../../form-builders/create-trainee";
import { useCallback, useState } from "react";
import api from "../../api/Api";
import _ from "lodash";
import Toast from "../../wrappers/Toast";
import { traineeSvc } from "../../services/Trainee";
import userSvc from "../../services/User";

export default function CreateTrainee(props) {
  const [formRenderCount, setFormRenderCount] = useState(0);

  const handleTraineeSubmit = useCallback(async (values) => {
    values.coupon_code = values.coupon_code.toUpperCase();

    let loader;
    try {
      const {
        email,
        mobile_number,
        coupon_code,
        qualification_id,
        school_name,
        gender,
      } = values;

      console.log("coupon_code==", coupon_code);

      /*
       *
       *
       * Checking trainee duplication and confirming force create
       *
       *
       */

      loader = Toast.load("Please wait...");
      const traineeSearchResponse = await api()
        .post("/search_trainees", { email, mobile_number })
        .then(({ data }) => data);
      const { data: duplicateTrainees } = traineeSearchResponse;
      Toast.endLoader(loader);

      let targetTrainee;

      if (duplicateTrainees?.length) {
        const traineePromptResponse = await Toast.prompt(
          DuplicateTraineesPrompt,
          {
            duplicateTrainees,
          }
        ).catch((err) => {
          throw new Error("Trainee enrollment was cancelled");
        });

        if (traineePromptResponse.trainee)
          targetTrainee = traineePromptResponse.trainee;
      }

      if (!targetTrainee) {
        /*
         *
         *
         * Registering user
         *
         *
         */
        const payload = _.pick(values, ["full_name", "password"]);
        payload.id = email || mobile_number;

        loader = Toast.load("Creating trainee");
        const response = await api()
          .post("/create_trainee", payload)
          .then(({ data }) => data);

        console.log("Create trainee response", response);

        if (!response.success) throw new Error(response.message);
        Toast.endLoader(loader);
        Toast.success(response.message || "Trainee created!");

        targetTrainee = response.data.db_user;
      } else {
        targetTrainee = await userSvc.getUserByUsername(targetTrainee.username);
      }

      /*
       *
       *
       * Update user details
       *
       *
       */
      console.log("updating user details");
      loader = Toast.load("Updating user profile info");
      const updateUserRes = await api()
        .post("/update_trainee_profile", {
          user_id: targetTrainee.id,
          qualification_id,
          school_name,
          gender,
        })
        .then(({ data }) => {
          if (!data.success) throw new Error("Couldn't update trainee profile");
          return data.data;
        });
      console.log("Update trainee profile response", updateUserRes);
      Toast.success("Updated trainee profile info!");
      Toast.endLoader(loader);

      /*
       *
       *
       * Applying coupon
       *
       *
       */
      loader = Toast.load("Applying coupon to trainee");
      const applyCouponRes = await traineeSvc.applyCouponCode(
        coupon_code,
        targetTrainee.id
      );

      if (!applyCouponRes.success)
        throw new Error("Coupon is already applied to the user");

      Toast.success("Coupon applied successfully!");

      setFormRenderCount((formRenderCount + 1) % 2);
    } catch (err) {
      Toast.error(err.message || "An unknown error occurred");
      console.error(err);
    } finally {
      Toast.endLoader(loader);
    }
  }, []);

  return (
    <Container title="Create Trainee">
      <Form
        className="grid grid-cols-2 gap-3"
        key={formRenderCount}
        formBuilder={TraineeFormBuilder}
        onSubmit={handleTraineeSubmit}
      />
    </Container>
  );
}

const DuplicateTraineesPrompt = ({ resolveFn, duplicateTrainees }) => {
  return (
    <div className="p-3 flex flex-col">
      <div className="text-md font-semibold">
        We have multiple trainees with the same email ID / phone number. Please
        choose one to proceed enrollment or create a new one
      </div>
      <ul className="mt-3">
        {duplicateTrainees.map((trainee) => (
          <li key={trainee.username} className="flex justify-between my-2">
            <div className="text-left">
              <span>{trainee.name.familyName}&nbsp;</span>
              <span className="font-semibold">({trainee.username})</span>
            </div>
            <div>
              <button
                onClick={() => resolveFn({ trainee })}
                className="btn-primary"
              >
                Update this trainee
              </button>
            </div>
          </li>
        ))}
      </ul>
      <button
        onClick={() => resolveFn({ forceCreate: true })}
        className="btn-primary"
      >
        Force create a new one
      </button>
    </div>
  );
};
