import { client } from "../App";
import { INSERT_GIO_LOCATION } from "../GraphQl/Mutations/GioLocation";
import { UPDATE_INSTRUCTOR } from "../GraphQl/Mutations/Instructor";
import { GET_INSTRUCTOR_BY_ID } from "../GraphQl/Queries/Instructor";
import { getCurrentLocation } from "../utils/Google";

class InstructorService {
  getInstructorById(instructorId) {
    return client
      .query({
        query: GET_INSTRUCTOR_BY_ID,
        variables: { instructorId },
      })
      .then(({ data }) => data.courses_instructor_by_pk);
  }
  async updateInstructor(instructorId, changes) {
    const updationResponse = await client
      .mutate({
        mutation: UPDATE_INSTRUCTOR,
        variables: { instructorId: instructorId, changes },
      })
      .then(({ data }) => data.update_courses_instructor_by_pk);
    return updationResponse;
  }

  async insertInstructorGeoLocation(instructorId) {
    console.log("Setting instructor location");
    const currentLocation = await getCurrentLocation();
    const geoLocationInsertionResponse = await client
      .mutate({
        mutation: INSERT_GIO_LOCATION,
        variables: {
          user_id: instructorId,
          location_latitude: currentLocation.coords.latitude,
          location_longitude: currentLocation.coords.longitude,
        },
      })
      .then(({ data }) => data.insert_courses_user_location_one);
    return geoLocationInsertionResponse;
  }
}

export const instructorService = new InstructorService();
