import axios from "axios";
import { error } from "./func";
import api from "../api/Api";

export const uploadLargeFile = async (file_data, file_name, isPublic) => {
  const data = {
    file_name,
    is_public: isPublic,
    content_type: file_data.type,
    collection: "assets",
  };
  try {
    const response = await api()
      .post("/get_file_post_url", data)
      .then(({ data }) => data);

    const { image_post_url, file_name: res_file_name } = response;
    await axios
      .put(image_post_url, file_data, {
        headers: {
          "content-type": file_data.type,
          "X-Amz-ACL": isPublic && "public-read",
        },
      })
      .then(({ data }) => console.log(data));

    return { data_url: res_file_name };
  } catch (err) {
    throw error("Couldn't upload file", err);
  }
};

export const getFile = async (file_url, is_large_file, collection) => {
  const data = { data_url: file_url, is_large_file, collection };
  const response = await api().post("/get_file", data);
  return response.data;
};
