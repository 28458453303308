import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import DashboardPage from "../../Pages/Dashboard/DashboardPage";
import LogoutPage from "../../Pages/Dashboard/LogoutPage";

import UserRoutes from "./UserRoutes";
import CreateTrainee from "../../Pages/Dashboard/CreateTrainee";
import BatchSlotsPage from "../../Pages/Dashboard/BatchSlotsPage";
import ClassHistoryPage from "../../Pages/Dashboard/ClassHistoryPage";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import Settings from "../../Pages/Dashboard/Settings/Settings";
import Trainee from "./Trainee";

const DashboardRoutes = (props) => {
  const { url } = useRouteMatch();

  const routes = [
    {
      path: `users`,
      component: UserRoutes,
    },
    {
      path: `batchslots`,
      component: BatchSlotsPage,
    },
    {
      path: `classhistory`,
      component: ClassHistoryPage,
    },
    {
      path: "trainee",
      component: Trainee,
    },
    {
      path: "create-trainee",
      component: CreateTrainee,
    },
    {
      path: `settings`,
      component: Settings,
    },
    {
      path: "logout",
      component: LogoutPage,
    },
    {
      component: DashboardPage,
    },
    ,
  ];

  return (
    <Switch>
      {routes.map(({ path, component }) => {
        // if (path == "settings") {
        //   return <CustomRoute path="/settings" component={Settings} />;
        // } else {
        return (
          <Route path={path ? `${url}${path}/:sidebarActive?` : undefined}>
            <DashboardLayout user={props.user} Component={component} />
          </Route>
        );
      })}
    </Switch>
  );
};

export default DashboardRoutes;
