import React from "react";
import DataTable from "react-data-table-component";
import batchUsersService from "../../../../services/BatchUsers";
import Container from "../../../../components/Container";
import moment from "moment";
import { useEffect, useState } from "react";

export default function QuizProgress(props) {
  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 10,
  });
  const columns = [
    {
      name: "Attempt No.",
      selector: (row) => row.attempt_number,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Attempt Start",
      selector: (row) => moment(row.attempt_start).format("YYYY-MM-DD"),
      sortable: true,
      width: "10rem",
    },
    {
      name: "Attempt End",
      selector: (row) => moment(row.attempt_end).format("YYYY-MM-DD"),
      sortable: true,
      width: "10rem",
    },
    {
      name: "Total Marks",
      selector: (row) => row.max_marks,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Attempt Marks",
      selector: (row) => row.total_score,
      sortable: true,
      width: "10rem",
    },

    {
      name: "Attempt Percentage",
      selector: (row) => row.attempt_percentage,
      sortable: true,
      width: "10rem",
    },

    {
      name: "Result",
      selector: (row) => row.result,
      sortable: true,
      width: "7rem",
    },

    {
      name: "Attempt Completed",
      selector: (row) => (row.attempt_completed ? "Completed" : "Ongoing"),
      sortable: true,
      width: "15rem",
    },
  ];

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });
  return (
    <DataTable
      columns={columns}
      data={props.progress ? props.progress : []}
      expandableRows={true}
      expandableRowsComponent={ExpandedComponent}
      expandOnRowClicked={false}
      expandOnRowDoubleClicked={false}
      expandableRowsHideExpander={false}
      pagination
      paginationServer
      paginationTotalRows={1000}
      // selectableRows
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
    />
  );
}

function ExpandedComponent(props) {
  const [questionAttempt, setQuestionAttempt] = useState([]);

  useEffect(() => {
    const attempt_id = props.data?.id;

    const questionAttempt = async () => {
      const response = await batchUsersService.getUserQuizQuestionAttempt(
        attempt_id
      );
      setQuestionAttempt(response);
    };

    questionAttempt();
  }, [props.data]);

  const columns = [
    {
      name: "Question name",
      selector: (row) => row.quiestionsobject.question_text,
      sortable: true,
      width: "30rem",
    },
    {
      name: "Corerct Answer",
      selector: (row) => row.question_correct_answer,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Response",
      selector: (row) => (row.response ? "Correct" : "Wrong"),
      sortable: true,
      width: "10rem",
    },
    {
      name: "Marks",
      selector: (row) => row.marks,
      sortable: true,
      width: "10rem",
    },
  ];

  return (
    <Container>
      <div class="p-2">
        <h1 class="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
          Trainee Question Response
        </h1>
      </div>
      <DataTable
        columns={columns}
        data={questionAttempt ? questionAttempt : []}
        pagination
        paginationServer
        paginationTotalRows={1000}
        // selectableRows
      />
    </Container>
  );
}
