import { gql } from "@apollo/client";

export const GET_INSTITUTES = gql`
  query getInstitutes($institutesCriteria: courses_institutes_bool_exp!) {
    courses_institutes(where: $institutesCriteria) {
      category
      city_town
      country
      created_at
      district
      id
      name
      pincode
      state
      updated_at
    }
  }
`;

export const FETCH_POSSIBLE_TECHNICAL_QUALIFICATION = gql`
  query getTechnicalQualifications {
    NSQF: courses_qualifications_lookup(
      where: { type: { _eq: "nsqf" } }
      order_by: { name: asc }
    ) {
      id
      name
      qp_code
    }
    T0: courses_qualifications_lookup(
      where: { type: { _eq: "diploma" } }
      order_by: { name: asc }
    ) {
      id
      name
      qp_code
    }
    T1: courses_qualifications_lookup(
      where: { type: { _eq: "iti" } }
      order_by: { name: asc }
    ) {
      id
      name
      qp_code
    }
    T2: courses_qualifications_lookup(
      where: { type: { _eq: "other" } }
      order_by: { name: asc }
    ) {
      id
      name
      qp_code
    }
  }
`;

export const FETCH_POSSIBLE_QUALIFICATIONS = gql`
  query getQualifications {
    twelve: courses_qualifications_lookup(where: { type: { _eq: "12" } }) {
      id
      name
    }
    bachelors: courses_qualifications_lookup(
      where: { type: { _eq: "bachelors" } }
    ) {
      id
      name
    }
    masters: courses_qualifications_lookup(
      where: { type: { _eq: "masters" } }
    ) {
      id
      name
    }
    phd: courses_qualifications_lookup(where: { type: { _eq: "phd" } }) {
      id
      name
    }
  }
`;
