import { gql } from "@apollo/client";
import { WORK_DETAILS_COLUMNS } from "../Queries/AccountDetails";

export const UPDATE_USER_BY_ID = gql`
  mutation updateUserById($idp_user_id: String, $trainee_photo_url: String) {
    update_courses_instructor(
      where: { idp_user_id: { _eq: $idp_user_id } }
      _set: { trainee_photo_url: $trainee_photo_url }
    ) {
      returning {
        id
      }
    }
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation createUser(
    $idp_user_id: String
    $email: String
    $mobile_number: String
    $name: String
    $partner_id: bigint
    $active: Boolean
  ) {
    insert_courses_users_one(
      object: {
        active: $active
        email: $email
        idp_user_id: $idp_user_id
        mobile_number: $mobile_number
        name: $name
        partner_id: $partner_id
      }
    ) {
      id
      name
      idp_user_id
    }
  }
`;
export const INSERT_WORK_DETAILS = gql`
  mutation createWorkDetails(
    $title: String!
    $company_name: String!
    $start_date: timestamptz!
    $end_date: timestamptz!
    $place: String!
    $user_id: bigint!
  ) {
    insert_courses_user_work_details_one(
      object: {
        title: $title
        company_name: $company_name
        start_date: $start_date
        end_date: $end_date
        place: $place
        user_id: $user_id
      }
    ) {
      title
      company_name
    }
  }
`;

export const UPDATE_USER_WORK_DETAILS_BY_USER_ID = gql`
  mutation updateUserById(
    $title: String
    $company_name: String
    $start_date: timestamptz
    $end_date: timestamptz
    $place: String
    $user_id: bigint
  ) {
    update_courses_user_work_details(
      where: { user_id: { _eq: $user_id } }
      _set: {
        title: $title
        company_name: $company_name
        start_date: $start_date
        end_date: $end_date
        place: $place
        user_id: $user_id
      }
    ) {
      returning {
        title
        company_name
        place
      }
    }
  }
`;
