import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  DELETE_BATCH_MEDIA_RELATION,
  INSERT_BATCH_MEDIA_RELATION,
  UPSERT_SKILLSTRAINER_MEDIA,
} from "../../../GraphQl/Mutations/Batch";
import { GET_BATCH_MEDIA_BY_ID } from "../../../GraphQl/Queries/BatchSlots";
import { Form } from "skillstrainer-resource-library";
import { errorHandler, successHandler } from "../../../utils/toast";
import { error, arrayDelta } from "../../../utils/func";
import { uploadLargeFile, getFile } from "../../../utils/UploadFile";
import UploadMediaFormBuilder from "../DashboardPage/UploadMediaFormBuilder";
import usePromise from "../../../utils/hooks/usePromise";
export default function UploadMediaModal(props) {
  const [fileTypeArray, setFileTypeArray] = useState([]);
  const [batchMedia, setBatchMedia] = useState({
    key: true,
    initValues: { file: [] },
  });

  const [getBatchMediaById] = useLazyQuery(GET_BATCH_MEDIA_BY_ID, {
    fetchPolicy: "network-only",
  });
  const [insertSkillstrainerMedia] = useMutation(UPSERT_SKILLSTRAINER_MEDIA);
  const [insertBatchMediaRelation] = useMutation(INSERT_BATCH_MEDIA_RELATION);
  const [deleteBatchMediaRelation] = useMutation(DELETE_BATCH_MEDIA_RELATION);

  const { batch } = props;
  const batchId = batch.id;

  useEffect(() => loadBatchData(), [batchId]);

  const loadBatchData = async () => {
    getBatchMediaById({
      variables: {
        batchId: batchId,
      },
    }).then((res) => {
      const response = res.data.courses_batch_media_relation;
      const initValues = {
        file: response.map((item) => {
          return {
            id: item.id,
            remark: item.remark || undefined,
            file_data: [
              {
                id: item.id,
                name: item.media?.file_name || "",
                url: item.media?.file_url,
              },
            ],
          };
        }),
      };

      setBatchMedia({ key: !batchMedia.key, initValues: initValues });
    });
  };

  const { run: handleSubmit, loading: submittingForm } = usePromise(
    async (media) => {
      const { created, deleted } = arrayDelta(
        batchMedia.initValues.file,
        media.file,
        ["id"]
      );

      const inputObject = created.map((f) => {
        return {
          file_url: f.file_data[0].url,
          file_name: f.file_data[0].name || null,
          file_type: fileTypeArray.find(
            (filetype) => filetype.name == f.file_data[0].name
          )?.type,
        };
      });

      const mediaArray = created.map((f) => {
        return {
          file_name: f.file_data[0].name || null,
          remark: f.remark || null,
        };
      });

      const deletedIdsArray = deleted.map((med) => med.id);

      await deleteBatchMediaRelation({
        variables: {
          ids: deletedIdsArray,
        },
      });

      await insertSkillstrainerMedia({
        variables: {
          objects: inputObject,
        },
      })
        .then((res) => {
          const mediaIdArray =
            res.data?.insert_courses_skillstrainer_media?.returning.map(
              (med) => {
                return {
                  media_id: med.id,
                  batch_id: batchId,
                  remark: mediaArray.find((f) => f.file_name == med.file_name)
                    ?.remark,
                };
              }
            );

          insertBatchMediaRelation({
            variables: {
              objects: mediaIdArray,
            },
          }).then(successHandler("Batch Media uploaded successfully"));
        })
        .catch((err) => {
          console.log(err);
          errorHandler("There was an error while uploading...");
        });
    }
  );

  // const submit = async (media) => {

  // };

  const saveFileData = (fileData) => {
    fileTypeArray.push({
      name: fileData.name || "",
      type: fileData.type || "",
    });
    setFileTypeArray([...fileTypeArray]);

    return console.log(fileData);
  };

  return (
    <div className="">
      <h1 className="ml-6 font-bold text-center mb-4 text-2xl lg:text-4xl text-japanese_indigo">
        Upload Batch Media
      </h1>
      <Form
        key={batchMedia.key}
        formBuilder={UploadMediaFormBuilder}
        className=" ml-6 mt-2 mb-4 grid grid-cols-2 gap-x-2"
        submitButton={{
          text: submittingForm ? "submitting..." : "Save",
          className:
            "btn-primary hover:opacity-80 text-sm font-semibold text-white rounded-md px-6 w-full",
          disabled: { submittingForm },
        }}
        onSubmit={handleSubmit}
        initValues={batchMedia.initValues}
        plugins={{
          file: {
            services: {
              uploadFn: (fileData) =>
                saveFileData(fileData) ||
                uploadLargeFile(fileData, fileData.name, true)
                  .then((res) => res.data_url)
                  .catch((e) => {
                    errorHandler("An error occured while uploading");
                    throw error("Couldn't upload file", e.data || e);
                  }),
              getUrl: (args) =>
                getFile(args, true, "assets").then(
                  (res) => console.log(res) || res
                ),
            },
          },
        }}
      />
    </div>
  );
}
