import React, { useEffect, useState } from "react";
import userSvc from "../../../services/User";
import { wireEventValue } from "../../../utils/func";
// import { successHandler } from "../../../utils/toast";
// import Toast from "../../../wrappers/Toast";
export default function EmailAndPassword(props) {
  const [email, setEmail] = useState({});
  const [emailError, setEmailError] = useState();

  const setEmailProp = (prop) => (val) => setEmail({ ...email, [prop]: val });
  const updateEmail = () => {
    if (email._new !== email.confirmNew) setEmailError("Emails don't match");
    else if (!email._new) setEmailError("Email can't be empty");
    else
      userSvc
        .updateEmail(email._new)
        .then((res) => {
          window.location.reload();
          // Toast.success("Successfully updated email!");
          setEmail({});
        })
        .catch((err) => {
          console.error(err);
          //   Toast.error("An error occured");
        });
  };

  const [password, setPassword] = useState({});
  const [passwordError, setPasswordError] = useState();
  const setPasswordProp = (prop) => (val) =>
    setPassword({ ...password, [prop]: val });
  const updatePassword = () => {
    if (password._new !== password.confirmNew)
      setPasswordError("New passwords don't match");
    else if (!password._new) setPasswordError("Password can't be empty");
    else
      userSvc
        .updatePassword(password._new)
        .then(() => window.location.reload())
        .catch((err) => {
          console.error(err);
          //   Toast.error("An error occured");
        });
  };

  return (
    <div className="p-2 pt-4">
      <div className="card p-4 pb-0 pt-5 mb-5 pb-5">
        <div className="b mb-5">Email</div>

        {emailError && <div className="error mb-3">{emailError}</div>}

        <div className="label mb-1">New email address</div>
        <input
          type="text"
          className="input mb-4"
          placeholder="Your new email address"
          onChange={wireEventValue(setEmailProp("_new"))}
          value={email._new || ""}
        />
        <div className="label mb-1">Confirm new email address</div>
        <input
          type="text"
          className="input mb-7"
          placeholder="Your new email address"
          onChange={wireEventValue(setEmailProp("confirmNew"))}
          value={email.confirmNew || ""}
        />

        <button onClick={updateEmail} className="button cta w-full">
          Update Email
        </button>
      </div>
      <div className="card p-4 pb-0 pt-5 mb-5 pb-5">
        <div className="b mb-5">Password</div>

        {passwordError && <div className="error mb-3">{passwordError}</div>}

        <div className="label mb-1">New password</div>
        <input
          type="password"
          className="input mb-4"
          placeholder="Your new password"
          onChange={wireEventValue(setPasswordProp("_new"))}
          value={password._new}
        />
        <div className="label mb-1">Confirm new password</div>
        <input
          type="password"
          className="input mb-7"
          placeholder="Your new password"
          onChange={wireEventValue(setPasswordProp("confirmNew"))}
          value={password.confirmNew}
        />

        <button onClick={updatePassword} className="button cta w-full">
          Update Password
        </button>
      </div>
    </div>
  );
}
