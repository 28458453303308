import React from "react";

export const RadioButton = (props) => {
  const { changed, id, isSelected, label, name, className, disabled } = props;
  return (
    <div className={`RadioButton mr-2 ${className}`}>
      <input
        id={id}
        onChange={changed}
        type="radio"
        checked={isSelected}
        name={name}
        disabled={disabled}
      />
      <label className="font-bold" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};
