import React, { useState, useMemo, useEffect } from "react";
import Container from "../../../components/Container";
import moment from "moment";
import batchUsersService from "../../../services/BatchUsers";
import { FcCheckmark } from "react-icons/fc";
import { GoX } from "react-icons/go";
import { RadioButton } from "../../../components/InputGroup/Radio";
import { successHandler, errorHandler } from "../../../utils/toast";
import { DATE_FORMATS } from "../../../api/Consts";

export default function TraineeAttendance(props) {
  const { refetchAttendance, batchSlots, userAttendance, batch } = props;
  const {
    id: batchId,
    batch_name: batchName,
    is_demo: isBatchDemo,
  } = batch || {};
  const [editMode, setEditMode] = useState(false);
  const [attendanceMap, setAttendanceMap] = useState({});

  const radioChangeHandler = (slot_id, user_id, attendance_status, id) => {
    const key = `${slot_id}-${user_id}`;
    let attendanceRecord = attendanceMap[key];

    if (!attendanceRecord) {
      attendanceRecord = attendanceMap[key] = {
        user_id,
        slot_id,
        attendance_status,
        status: attendance_status,
        batch_id: batchId,
        id,
      };
    } else
      attendanceRecord.attendance_status = attendanceRecord.status =
        attendance_status;

    setAttendanceMap({ ...attendanceMap });
  };

  const updateAttendance = async () => {
    await Promise.all(
      Object.values(attendanceMap).map((attendanceRecord) => {
        if (attendanceRecord.id)
          return batchUsersService.updateUserAttendance(attendanceRecord);
        else return batchUsersService.insertUserAttendance(attendanceRecord);
      })
    );
    setAttendanceMap({});
    setEditMode(false);
    await refetchAttendance();
    successHandler("Attendance Updated Successfully!");
  };

  return (
    <Container>
      <div className="p-2">
        <h1 className="font-bold text-center mb-4 text-2xl lg:text-4xl text-japanese_indigo">
          Attendance: {batchName}
        </h1>
      </div>

      {!isBatchDemo ? (
        <>
          <div className="mb-4 flex justify-end">
            <button
              className="btn-primary"
              onClick={() =>
                !editMode ? setEditMode(true) : updateAttendance()
              }
            >
              {!editMode ? "Edit" : "Update"} Attendances{" "}
            </button>
          </div>

          <div
            className="overflow-x-auto relative shadow-md sm:rounded-lg max-w-full"
            style={{ maxWidth: "calc(100vw - 30px)" }}
          >
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="py-3 px-6">
                    Trainees Name
                  </th>

                  {batchSlots &&
                    batchSlots.map((bs, index) => {
                      return (
                        <th
                          key={bs.id}
                          scope="col"
                          className="py-3 px-6"
                          style={{ minWidth: "4rem" }}
                        >
                          Slots {index + 1} (
                          {moment(bs.slot_date).format("ddd")})
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {userAttendance &&
                  userAttendance.map((userAttendanceRecord) => {
                    const { user } = userAttendanceRecord;
                    return (
                      <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <th
                          scope="row"
                          className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white max-w-md"
                        >
                          {user.name}
                        </th>

                        {batchSlots.map((bs) => {
                          const allowed = new Date() > new Date(bs.slot_date),
                            user_id = user.id,
                            slot_id = bs.id;

                          const userBatchSlotAttendance =
                            userAttendanceRecord.attendance.find(
                              ({ slot_id }) => slot_id === bs.id
                            );

                          const attendanceMapItem =
                            attendanceMap[`${slot_id}-${user_id}`];

                          const { id: ua_id, status } =
                            attendanceMapItem || userBatchSlotAttendance || {};

                          return (
                            <td className="py-4 px-6">
                              {editMode ? (
                                <div
                                  className={`radio-btn-container ${
                                    !allowed
                                      ? "cursor-not-allowed border-dashed	border-2 border-red-500"
                                      : ""
                                  }`}
                                  style={{ display: "flex" }}
                                >
                                  <RadioButton
                                    className={`${
                                      allowed ? "cursor-not-allowed" : ""
                                    }`}
                                    changed={(e) => {
                                      allowed
                                        ? radioChangeHandler(
                                            slot_id,
                                            user_id,
                                            true,
                                            ua_id
                                          )
                                        : errorHandler(
                                            "Not Allowed To Mark Future Attendance"
                                          );
                                    }}
                                    label="P"
                                    name={`${slot_id}-${user_id}`}
                                    isSelected={status === true}
                                    disabled={!allowed}
                                  />

                                  <RadioButton
                                    className={`${
                                      !allowed ? "cursor-not-allowed" : ""
                                    }`}
                                    changed={(e) =>
                                      allowed
                                        ? radioChangeHandler(
                                            slot_id,
                                            user_id,
                                            false,
                                            ua_id
                                          )
                                        : errorHandler(
                                            "Not Allowed To Mark Future Attendance"
                                          )
                                    }
                                    name={`${slot_id}-${user_id}`}
                                    label="A"
                                    isSelected={status === false}
                                    disabled={!allowed}
                                  />
                                </div>
                              ) : status === true ? (
                                <FcCheckmark className="font-bold text-green-800 text-lg	" />
                              ) : status === false ? (
                                <GoX className="font-bold text-red-800" />
                              ) : (
                                status
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <ul>
          {batchSlots.map((bs) => {
            const users = Object.values(userAttendance)
              .map((record) => {
                if (record.attendance.find((a) => a.slot_id === bs.id))
                  return record.user;
              })
              .filter((e) => e);

            return (
              <li className="mb-3">
                <div className="font-semibold mb-1">
                  {moment(bs.slot_date).format(
                    DATE_FORMATS.BATCH_SLOT_DATE_TIME
                  )}
                </div>
                {users.length > 0 ? (
                  <ul>
                    {users.map((user) => (
                      <li>
                        <span>{user.email}</span>{" "}
                        <span className="text-gray-500">({user.name})</span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <span className="font-semibold text-gray-400 text-sm">
                    No users attended
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </Container>
  );
}
