import React, { useEffect } from "react";
import { logoutUser } from "../../api/Auth";

function LogoutPage() {
  useEffect(async () => {
    await logoutUser();
    window.location.replace("/");
  });
  return <div></div>;
}

export default LogoutPage;
