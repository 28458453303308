import * as yup from "yup";
import { useState, useEffect } from "react";

function UserFormBuilder(values) {
  const fileSchema = yup.array().of(yup.object());

  const [schema, setSchema] = useState({});

  useEffect(() => {
    const schema = {
      photo: {
        label: "Profile Photo",
        type: "file",
        schema: fileSchema,
      },
    };

    setSchema(schema);
  }, []);

  return schema;
}

export default UserFormBuilder;
