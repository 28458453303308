import * as yup from "yup";
import { useState, useEffect } from "react";

function UploadMediaFormBuilder(values) {
  const [schema, setSchema] = useState();

  useEffect(() => {
    const schema = {
      file: {
        type: "object",
        label: "Batch Media",
        repeat: true,
        insertable: true,
        fields: {
          id: {
            hidden: true,
            type: "text",
            schema: yup.string(),
          },
          file_data: {
            label: "Batch Media",
            type: "file",
            required: true,
            schema: yup.array().of(
              yup.object({
                id: yup.string(),
                url: yup.string(),
                name: yup.string(),
              })
            ),
          },
          remark: {
            label: "Remark",
            type: "textarea",
            schema: yup.string(),
          },
        },
      },
    };
    setSchema(schema);
  }, [values]);

  return schema;
}

export default UploadMediaFormBuilder;
