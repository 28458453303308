export let domain = "";

export const env = {
  isProduction: process.env.REACT_APP_BUILD_ENV == "production",
  isStaging: process.env.REACT_APP_BUILD_ENV == "staging",
  isDev: [undefined, "dev", "development"].includes(
    process.env.REACT_APP_BUILD_ENV
  ),
  useLocalApi: !!process.env.REACT_APP_USE_LOCAL_API,
};
export const UPLOAD_COLLECTIONS = {
  assets: "assets",
};
export let graphqlWsUri = "";
export const TECHNICAL_QUALIFICATION_TYPE = {
  DIPLOMA: "T0",
  ITI: "T1",
  NSQF: "NSQF",
  OTHER: "T2",
};
export const ANNUAL_INCOME_SLABS = {
  1: "Less than 1L",
  2: "1L - 2.5L",
  3: "2.5L - 5L",
  4: "5L and above",
};
export const CONTACT_PERSON_TYPE = {
  TEACHER: "0",
  PRINCIPAL: "1",
  OTHER: "2",
};
export const CONTACT_PERSON_NAMES = {
  [CONTACT_PERSON_TYPE.PRINCIPAL]: "Principal",
  [CONTACT_PERSON_TYPE.TEACHER]: "Teacher",
  [CONTACT_PERSON_TYPE.OTHER]: "Other",
};

export const TECHNICAL_QUALIFICATION_NAMES = {
  [TECHNICAL_QUALIFICATION_TYPE.DIPLOMA]: "Diploma",
  [TECHNICAL_QUALIFICATION_TYPE.ITI]: "ITI",
  [TECHNICAL_QUALIFICATION_TYPE.NSQF]: "NSQF Certification",
  [TECHNICAL_QUALIFICATION_TYPE.OTHER]: "Other",
};

export const MARKING_TYPE = {
  grade: "grade",
  percentage: "percentage",
};
export const MARKING_TYPE_NAMES = {
  [MARKING_TYPE.grade]: "Grade",
  [MARKING_TYPE.percentage]: "Percentage",
};

if (env.isProduction) {
  domain = "https://inadmin.skillstrainer.in";
  if (env.useLocalApi) domain = "http://inadmin.skillstrainer-dev.co";
} else if (env.isStaging) {
  domain = "https://inadmin.skillsscale.in";
} else {
  domain = "http://inadmin.skillstrainer-dev.co";
}

export let graphqlUri = "http://65.0.60.167:8080/v1/graphql";

if (env.isProduction) {
  graphqlUri = "https://graphql.skillstrainer.in/v1/graphql";
} else if (env.isStaging) {
  graphqlUri = "https://graphql.skillsscale.in/v1/graphql";
} else {
  graphqlUri = "https://graphql.skillsscale.in/v1/graphql";
}

export const DATE_FORMATS = {
  BATCH_SLOT_DATE: "DD MMM YY",
  BATCH_SLOT_TIME: "HH:mm",
  BATCH_SLOT_DATE_TIME: "DD MMM YY, HH:mm",
};
export let SCHOOL_QUALIFICATIONS_HEIRARCHY = [];
export const DOCUMENT_TYPE = {
  AADHAR: "0",
  PASSPORT: "1",
  RATION: "2",
  DRIVING_LICENSE: "3",
  INCOME_CERT: "4",
  FAMILY_INCOME_CERT: "9",
  ACADEMIC_CERT: "5",
  RECOMMENDATION_CERT_FROM_INSTITUTE: "6",
  RECOMMENDATION_CERT_FROM_ROTARY: "10",
  EXTRA_CURRICULAR_CERT: "7",
  DOCUMENT_PROOF: "8",
  DOCUMENT_PROOF_TECHINCAL: "12",
  OTHER_CERT: "11",
  OTHER: "10",
};

export let marketingUri = "https://skillstrainer.in";

export let SCHOOL_QUALIFICATIONS = [];

if (env.isProduction)
  SCHOOL_QUALIFICATIONS = [
    { name: "1st", id: 10 },
    { name: "2nd", id: 11 },
    { name: "3rd", id: 12 },
    { name: "4th", id: 13 },
    { name: "5th", id: 1 },
    { name: "6th", id: 14 },
    { name: "7th", id: 15 },
    { name: "8th", id: 2 },
    { name: "9th", id: 3 },
    { name: "10th", id: 4 },
    { name: "11th", id: 5 },
    { name: "12th", id: 6 },
    { name: "12th (Science)", id: 1963 },
    { name: "12th (Commerce)", id: 1964 },
    { name: "12th (Arts)", id: 1965 },
  ];
else
  SCHOOL_QUALIFICATIONS = [
    {
      id: 10,
      name: "1st Std",
    },
    {
      id: 11,
      name: "2nd Std",
    },
    {
      id: 12,
      name: "3rd Std",
    },
    {
      id: 13,
      name: "4th Std",
    },
    {
      id: 1,
      name: "5th Std",
    },
    {
      id: 14,
      name: "6th Std",
    },
    {
      id: 15,
      name: "7th Std",
    },
    {
      id: 2,
      name: "8th Std",
    },
    {
      id: 3,
      name: "9th Std",
    },
    {
      id: 4,
      name: "10th Std",
    },
    {
      id: 5,
      name: "11th Std",
    },
    {
      id: 6,
      name: "12th Std",
    },
    {
      id: 1965,
      name: "12th Std (Arts)",
    },
    {
      id: 1964,
      name: "12th Std (Commerce)",
    },
    {
      id: 1963,
      name: "12th Std (Science)",
    },
  ];

export let GENDERS = [
  {
    id: 0,
    name: "Male",
  },
  {
    id: 1,
    name: "Female",
  },
  {
    id: 2,
    name: "Other",
  },
];

export const LOCATION_TRACKING_INTERVAL = 1000 * 60;

export const asdmCourseReportConfig = {};

if (env === "production")
  Object.assign(asdmCourseReportConfig, {
    partnerId: 51,
    courseId: 178,
    projectId: 18,
    mappingId: 1335,
  });
else
  Object.assign(asdmCourseReportConfig, {
    partnerId: 51,
    courseId: 178,
    projectId: 18,
    mappingId: 32462346,
  });
