import React, { Component, useEffect, useState } from "react";
import UsersPage from "../../Pages/Dashboard/UsersPage";
import BatchSlotsPage from "../../Pages/Dashboard/BatchSlotsPage";
import {
  Switch,
  Route,
  useParams,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import Container from "../../components/Container";
import { useBasePath } from "../../components/useBaseUrl/useBaseUrl";
import { AiOutlineSearch } from "react-icons/ai";
import { MdManageAccounts } from "react-icons/md";

function UserRoutes({ user, setSidebar, sidebar }) {
  const items = [
    {
      icon: AiOutlineSearch,
      name: "Search profile",
      url: "profile",
      Component: <UsersPage user={user} />,
    },
    {
      icon: AiOutlineSearch,
      name: "Batch Slots",
      url: "batchslots",
      Component: <BatchSlotsPage user={user} />,
    },
  ];

  const { sidebarActive } = useParams();
  const { pathname } = useLocation();
  const baseurl = useBasePath();
  const setSidebarItems = () => setSidebar({ items, active: 0 });
  useEffect(() => {
    setSidebarItems();
  }, []);
  return (
    <Container user={user} title={"Manage Profile"}>
      {items[sidebar.active] && items[sidebar.active].Component}
    </Container>
  );
}

export default UserRoutes;
