import { useEffect, useCallback, useState, useRef } from "react";
import { instructorService } from "../../services/Instructor";
import { LOCATION_TRACKING_INTERVAL } from "../../api/Consts";

export default function GeoLocation({ user }) {
  /*
   *
   *
   * Instructor details
   *
   *
   */
  const instructorId = user?.id;
  const [instructor, setInstructor] = useState();
  useEffect(() => {
    if (instructorId)
      instructorService.getInstructorById(instructorId).then(setInstructor);
  }, [instructorId]);
  const updateInstructor = useCallback(
    async (changes) =>
      instructorService
        .updateInstructor(instructorId, changes)
        .then(setInstructor),
    [instructorId, setInstructor]
  );

  /*
   *
   *
   * Instructor location tracking
   *
   *
   */
  const locationTrackingIntervalRef = useRef();

  const stopTrackingLocation = useCallback(() => {
    console.log("Stopping location tracking");
    if (locationTrackingIntervalRef.current)
      clearInterval(locationTrackingIntervalRef.current);
    console.log("Stopped location tracking");
  }, []);

  const startTrackingLocation = useCallback(() => {
    console.log("Starting location tracking");

    stopTrackingLocation();

    const execFunc = () =>
      instructorService
        .insertInstructorGeoLocation(instructorId)
        .then((res) => console.log("Set user geolocation", res))
        .catch((err) => console.error(err) || clearInterval(newInterval));

    execFunc();
    const newInterval = setInterval(execFunc, LOCATION_TRACKING_INTERVAL);
    locationTrackingIntervalRef.current = newInterval;
  }, [instructorId, stopTrackingLocation]);

  useEffect(() => {
    if (instructor) {
      if (instructor.has_allowed_location) startTrackingLocation();
      else stopTrackingLocation();
    }
  }, [instructor, startTrackingLocation, stopTrackingLocation]);

  return (
    <div>
      <button
        className="btn-primary"
        onClick={() =>
          updateInstructor({
            has_allowed_location: !instructor.has_allowed_location,
          })
        }
      >
        {instructor?.has_allowed_location
          ? "Revoke location permission"
          : "Grant location permission"}
      </button>
    </div>
  );
}
