import api from "../api/Api";

class TraineeService {
  async applyCouponCode(coupon_code, userId) {
    return api()
      .post("/apply_coupon_to_trainee", { user_id: userId, coupon_code })
      .then(({ data }) => {
        if (!data.success) {
          if (data.message.type === "invalid_coupons")
            throw new Error("Invalid coupon code");
          else if (data.message.type === "coupon_already_applied")
            throw new Error("Coupon already applied");
          else
            throw new Error(
              typeof data.message === "string"
                ? data.message
                : "An error occurred"
            );
        }
        return data;
      });
  }
}

export const traineeSvc = new TraineeService();
