/* eslint-disable jsx-a11y/anchor-is-valid */
import Container from "../../components/Container";

export default function ClassHistoryPage() {
  return (
    <>
      <Container title={"Class History"}>
        <h1 className="text-2xl font-bold">Your Class History</h1>
        <div className="mx-3">
          <p>Filter by date:</p>
          <input className="mx-2" type="date" name="start-date" id="" />
          <input className="mx-2" type="date" name="end-date" id="" />
          <button className="bg-light-orange text-white p-2 rounded-md mx-2">
            Filter
          </button>
        </div>

        <div className="p-3">
          <div className="flex flex-row bg-white rounded-lg shadow-md p-5 w-4/5 my-2">
            <div className="w-2/5 mx-2">
              <img
                className="h-56 w-full object-cover object-center shadow-md"
                src="https://via.placeholder.com/350x150.png?text=No+Course+Image"
                alt="course-img"
              ></img>
            </div>

            <div className="w-3/5">
              <p>
                <span className="font-bold">Batch Name: </span>
              </p>
              <p>
                <span className="font-bold">Course Details: </span>
              </p>
              <p>
                <span className="font-bold">Batch Details: </span>Lorem ipsum
                lorem
              </p>
              <p>
                <span className="font-bold">Slot Date and Time: </span>
              </p>
              <p>
                <span className="font-bold">Number of Enrolled Trainees: </span>
                20
              </p>
              <div className="flex flex-row">
                <a
                  href="#"
                  className="bg-yellow-100 text-gray-600 cursor-pointer no-underline p-2 rounded-md font-semibold mx-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Go to Course
                </a>
              </div>
            </div>
          </div>

          <div className="flex flex-row bg-white rounded-lg shadow-md p-5 w-4/5 my-2">
            <div className="w-2/5 mx-2">
              <img
                className="h-56 w-full object-cover object-center shadow-md"
                src="https://via.placeholder.com/350x150.png?text=No+Course+Image"
                alt="course-img"
              ></img>
            </div>

            <div className="w-3/5">
              <p>
                <span className="font-bold">Batch Name: </span>
              </p>
              <p>
                <span className="font-bold">Course Details: </span>
              </p>
              <p>
                <span className="font-bold">Batch Details: </span>Lorem ipsum
                lorem
              </p>
              <p>
                <span className="font-bold">Slot Date and Time: </span>
              </p>
              <p>
                <span className="font-bold">Number of Enrolled Trainees: </span>
                20
              </p>
              <div className="flex flex-row">
                <a
                  href="#"
                  className="bg-yellow-100 text-gray-600 cursor-pointer no-underline p-2 rounded-md font-semibold mx-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Go to Course
                </a>
              </div>
            </div>
          </div>

          <div className="flex flex-row bg-white rounded-lg shadow-md p-5 w-4/5 my-2">
            <div className="w-2/5 mx-2">
              <img
                className="h-56 w-full object-cover object-center shadow-md"
                src="https://via.placeholder.com/350x150.png?text=No+Course+Image"
                alt="course-img"
              ></img>
            </div>

            <div className="w-3/5">
              <p>
                <span className="font-bold">Batch Name: </span>
              </p>
              <p>
                <span className="font-bold">Course Details: </span>
              </p>
              <p>
                <span className="font-bold">Batch Details: </span>Lorem ipsum
                lorem
              </p>
              <p>
                <span className="font-bold">Slot Date and Time: </span>
              </p>
              <p>
                <span className="font-bold">Number of Enrolled Trainees: </span>
                20
              </p>
              <div className="flex flex-row">
                <a
                  href="#"
                  className="bg-yellow-100 text-gray-600 cursor-pointer no-underline p-2 rounded-md font-semibold mx-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Go to Course
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
