import React, { useState, useEffect } from "react";
import { LoginUser, checkUserAuthentication } from "../../api/Auth";
import { marketingUri } from "../../api/Consts";
import { LogoBlue } from "../../assets/Logo";

const LoginPage = ({ setAuthenticated, setUser }) => {
  const [inputData, setInputData] = useState({
    email: " ",
    password: " ",
  });
  const [errors, setErrors] = useState("");
  const handleChange = ({ target: { id, value } }) => {
    setInputData({ ...inputData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors("");
    if (!inputData.email || !inputData.password)
      return setErrors("field cannot be empty");
    const LoginResponse = await LoginUser(inputData.email, inputData.password);
    if (!LoginResponse.success) return setErrors(LoginResponse.message);

    setUser(LoginResponse.db_user);
    setAuthenticated(true);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 ">
      <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
        <img className="mx-auto w-48 my-4" alt="SkillsTrainer" src={LogoBlue} />
        <div className="font-semibold self-center text-xl sm:text-2xl uppercase">
          Trainer PORTAL LOGIN
        </div>

        <div className="mt-10">
          <form
            className="w-full my-auto"
            onSubmit={handleSubmit}
            onChange={handleChange}
          >
            <div className="flex flex-col mb-6">
              <label
                htmlFor="email"
                className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
              >
                E-Mail Address:
              </label>
              <div className="relative flex items-center">
                <div className="inline-flex items-center justify-center left-0 top-0 h-full w-10 text-gray-400">
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                  </svg>
                </div>
                <input
                  id="email"
                  type="email"
                  name="email"
                  className="input-primary"
                  placeholder="E-Mail Address"
                />
              </div>
            </div>

            <div className="flex flex-col mb-6">
              <label
                htmlFor="password"
                className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
              >
                Password:
              </label>
              <div className="relative flex items-center">
                <div className="inline-flex items-center justify-center left-0 top-0 h-full w-10 text-gray-400">
                  <span>
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                    </svg>
                  </span>
                </div>
                <input
                  id="password"
                  type="password"
                  name="password"
                  className="input-primary"
                  placeholder="Password"
                />
              </div>
            </div>

            <div className="flex w-full">
              <button
                type="submit"
                className="flex items-center justify-center focus:outline-none text-lg font-semibold bg-orange-800 hover:opacity-90 rounded py-2 w-full transition duration-150 ease-in"
              >
                <span className="mr-2 uppercase">Login</span>
                <span>
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
              </button>
            </div>
          </form>
          {errors && (
            <div
              className="text-center bg-red-200 text-red-500 font-semibold mt-8 p-1 rounded-md"
              role="alert"
            >
              {errors}
            </div>
          )}
        </div>
      </div>
      <div className="w-full absolute bottom-0 left-0 flex justify-center p-10 gap-x-20 box-border">
        <a href={marketingUri} target="_blank" rel="noreferrer">
          Skillstrainer.in
        </a>
        <a href={`${marketingUri}/about`} target="_blank" rel="noreferrer">
          About Us
        </a>
        <a href={`${marketingUri}/privacy`} target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
      </div>
    </div>
  );
};

export default LoginPage;
