import { gql } from "@apollo/client";

export const UPDATE_INSTRUCTOR = gql`
  mutation UpdateInstructor(
    $instructorId: bigint!
    $changes: courses_instructor_set_input!
  ) {
    update_courses_instructor_by_pk(
      pk_columns: { id: $instructorId }
      _set: $changes
    ) {
      id
      email
      name
      idp_user_id
      idp_user_name
      has_allowed_location
    }
  }
`;
