import { apolloClient } from "../App";
import {
  ATTACH_ADDRESS_TO_USER_MUTATION,
  ATTACH_ADDRESS_TO_QUALIFICATION_MUTATION,
  INSERT_IDENTITY_DOCUMENT_MUTATION,
  INSERT_USER_ADDRESS_MUTATION,
  UPDATE_IDENTITY_DOCUMENT_MUTATION,
  UPDATE_USER_ADDRESS_MUTATION,
  UPDATE_USER_MUTATION,
  INSERT_MEMBER_MUTATION,
  UPDATE_MEMBER_MUTATION,
  INSERT_ACADEMIC_QUALIFICATIONS,
  INSERT_SKILL,
  INSERT_LANGUAGE,
  UPDATE_ACADEMIC_QUALIFICATIONS,
  UPDATE_LANGUAGE,
  UPDATE_USER_TAG,
  INSERT_USER_TAG,
  DELETE_MEMBER_MUTATION,
  ATTACH_IDENTITY_DOCUMENT_TO_QUALIFICATION_MUTATION,
  DELETE_IDENTITY_DOCUMENT_MUTATION,
  DELETE_USER_ADDRESS_MUTATION,
  DELETE_ACADEMIC_QUALIFICATION_MUTATION,
  DELETE_SKILL_MUTATION,
  DELETE_LANGUAGE_MUTATION,
  UPDATE_WORK_DETAILS,
  INSERT_WORK_DETAILS,
  DELETE_WORK_DETAILS,
} from "../GraphQl/Mutations/AccountDetails";
import {
  FETCH_USER_MEMBERS_BY_TYPE_QUERY,
  FETCH_USER_MEMBERS_QUERY,
  FETCH_USER_QUERY,
  FETCH_ACADEMIC_QUALIFICATIONS,
  FETCH_SKILLS,
  FETCH_LANGUAGES,
  FETCH_USER_TAGS,
  FETCH_WORK_DETAILS,
} from "../GraphQl/Queries/AccountDetails";
import { jsonToGraphQLQuery, VariableType } from "json-to-graphql-query";
import { gql } from "@apollo/client";
import Axios from "../api/Api";
import Toast from "../wrappers/Toast";
import _ from "lodash";
import { DOCUMENT_TYPE, SCHOOL_QUALIFICATIONS_HEIRARCHY } from "../api/Consts";
import { updateObject } from "../utils/func";
import { error } from "../utils/error";
import { GET_USER_BY_USERNAME } from "../GraphQl/Queries/User";

class UserService {
  getUserByUsername(idp_user_name) {
    const idp_user_names = [idp_user_name];
    if (idp_user_name.indexOf("/") > -1)
      idp_user_names.push(idp_user_name.split("/").slice(1).join("/"));
    else idp_user_names.push("PRIMARY/" + idp_user_name);

    return apolloClient
      .query({ query: GET_USER_BY_USERNAME, variables: { idp_user_names } })
      .then(({ data }) => data.courses_users[0]);
  }

  // Email and password
  updateEmail(email) {
    return Axios().post("update_email", { email });
  }

  updatePassword(password) {
    return Axios().post("reset_password", {
      password,
      confirm_password: password,
    });
  }

  fetchUserDetails(userId) {
    return apolloClient
      .query({
        query: FETCH_USER_QUERY,
        variables: { id: userId },
      })
      .then(({ data }) => data.courses_users[0])
      .catch(console.error);
  }
  async fetchUserDetailsByIdentifier(identifier) {
    const response = await Axios.post("/get_user", { identifier })
      .then(({ data }) => data)
      .catch(() => false);
    return response && response && response.success && response.data;
  }
  async fetchUserDetail(userId, detail) {
    const user = await this.fetchUserDetails(userId).catch(console.error);
    return user && user[detail];
  }
  updateUserDetails(userId, values) {
    return apolloClient
      .mutate({
        mutation: UPDATE_USER_MUTATION,
        variables: { id: userId, values },
      })
      .then(({ data }) => data.update_courses_users.returning[0])
      .catch(console.error);
  }
  attachAddressToUser(addressId, userId) {
    return apolloClient.mutate({
      mutation: ATTACH_ADDRESS_TO_USER_MUTATION,
      variables: {
        userId,
        addressId,
      },
    });
  }

  // Address
  async createAddress(values) {
    // todo: convert this into a custom mutation done on SQL due to potential network issues on client side
    const address = await apolloClient
      .mutate({
        mutation: INSERT_USER_ADDRESS_MUTATION,
        variables: { values },
      })
      .then(({ data }) => data.insert_courses_user_address.returning[0])
      .catch(console.error);
    return address;
  }
  updateAddress(values) {
    const { id } = values;
    delete values.id;
    delete values.user_id;
    return apolloClient
      .mutate({
        mutation: UPDATE_USER_ADDRESS_MUTATION,
        variables: { id, values },
      })
      .then(({ data }) => data.update_courses_user_address.returning[0])
      .catch(console.error);
  }
  async createOrUpdateAddress(values) {
    if (values.id) return this.updateAddress(values);
    else return this.createAddress(values);
  }
  deleteAddress(id) {
    return apolloClient
      .mutate({
        mutation: DELETE_USER_ADDRESS_MUTATION,
        variables: { id },
      })
      .then(({ data }) => data.delete_courses_user_address.affected_rows);
  }

  // Identity documents
  fetchIdentityDocument(values) {
    const query = jsonToGraphQLQuery({
      query: {
        courses_user_identity_documents: {
          __args: {
            where: Object.keys(values).reduce((acc, cur) => {
              acc[cur] = { _eq: values[cur] };
              return acc;
            }, {}),
          },
          id: true,
          document_type: true,
          user_id: true,
          url: true,
          name: true,
          description: true,
        },
      },
    });
    return apolloClient
      .query({
        query: gql(query),
      })
      .then(({ data }) => _.cloneDeep(data.courses_user_identity_documents))
      .catch(console.error);
  }
  createIdentityDocument(values) {
    return apolloClient
      .mutate({
        mutation: INSERT_IDENTITY_DOCUMENT_MUTATION,
        variables: { values },
      })
      .then(({ data }) => data.insert_courses_user_identity_documents.returning)
      .catch(console.error);
  }
  updateIdentityDocument(values) {
    const { id } = values;
    delete values.id;
    return apolloClient
      .mutate({
        mutation: UPDATE_IDENTITY_DOCUMENT_MUTATION,
        variables: { id, values },
      })
      .then(
        ({ data }) => data.update_courses_user_identity_documents.returning[0]
      )
      .catch(console.error);
  }
  createOrUpdateIdentityDocument(values) {
    if (values.id) return this.updateIdentityDocument(values);
    else return this.createIdentityDocument(values);
  }
  deleteIdentityDocuments(ids) {
    return apolloClient
      .mutate({
        mutation: DELETE_IDENTITY_DOCUMENT_MUTATION,
        variables: { ids },
      })
      .then(
        ({ data }) => data.delete_courses_user_identity_documents.affected_rows
      );
  }

  // Family Members
  fetchUserMembers(userId) {
    return apolloClient
      .query({
        query: FETCH_USER_MEMBERS_QUERY,
        variables: { userId },
      })
      .then(({ data }) => data.courses_user_family_details);
  }
  fetchMemberByType(memberType, userId) {
    return apolloClient
      .query({
        query: FETCH_USER_MEMBERS_BY_TYPE_QUERY,
        variables: {
          memberType,
          userId,
        },
      })
      .then(({ data }) => data.courses_user_family_details)
      .catch(console.error);
  }
  createMemberDetails(values) {
    return apolloClient
      .mutate({
        mutation: INSERT_MEMBER_MUTATION,
        variables: { values },
      })
      .then(({ data }) => data.insert_courses_user_family_details.returning[0]);
  }
  updateMemberDetails(memberId, values) {
    delete values.id;
    delete values.user_id;
    return apolloClient
      .mutate({
        mutation: UPDATE_MEMBER_MUTATION,
        variables: {
          id: memberId,
          values,
        },
      })
      .then(({ data }) => data.update_courses_user_family_details.returning[0]);
  }
  deleteMemberDetails(memberIds) {
    return apolloClient
      .mutate({
        mutation: DELETE_MEMBER_MUTATION,
        variables: { memberIds },
      })
      .then(
        ({ data }) => data.delete_courses_user_family_details.affected_rows
      );
  }
  createOrUpdateMemberDetails(values) {
    if (values.id) return this.updateMemberDetails(values.id, values);
    else return this.createMemberDetails(values);
  }

  // Qualifications
  fetchUserQualifications(userId) {
    return apolloClient
      .query({
        query: FETCH_ACADEMIC_QUALIFICATIONS,
        variables: { userId },
      })
      .then(({ data }) => data.courses_user_academic_qualifications);
  }
  createQualification(values) {
    return apolloClient
      .mutate({
        mutation: INSERT_ACADEMIC_QUALIFICATIONS,
        variables: { values },
      })
      .then(
        ({ data }) =>
          data.insert_courses_user_academic_qualifications.returning[0]
      );
  }
  updateQualification(qualificationId, values) {
    delete values.id;
    delete values.user_id;
    return apolloClient
      .mutate({
        mutation: UPDATE_ACADEMIC_QUALIFICATIONS,
        variables: { qualificationId, values },
      })
      .then(
        ({ data }) =>
          data.update_courses_user_academic_qualifications.returning[0]
      );
  }
  createOrUpdateQualification(values) {
    if (values.id) return this.createQualification(values);
    else return this.updateQualification(values.id, values);
  }
  deleteQualification(id) {
    return apolloClient
      .mutate({
        mutation: DELETE_ACADEMIC_QUALIFICATION_MUTATION,
        variables: { id },
      })
      .then(
        ({ data }) =>
          data.delete_courses_user_academic_qualifications.affected_rows
      );
  }
  attachAddressToQualification({ addressId, qualId }) {
    return apolloClient.mutate({
      mutation: ATTACH_ADDRESS_TO_QUALIFICATION_MUTATION,
      variables: {
        qualId,
        addressId,
      },
    });
  }
  attachIdentityDocumentToQualification({ docId, qualId }) {
    return apolloClient
      .mutate({
        mutation: ATTACH_IDENTITY_DOCUMENT_TO_QUALIFICATION_MUTATION,
        variables: { docId, qualId },
      })
      .then(
        ({ data }) =>
          data.update_courses_user_academic_qualifications.returning[0]
      );
  }
  async setUserQualifications(allQuals, userId) {
    const qualsToCreate = allQuals.filter((q) => !q.disabled && q.fresh);
    const qualsToUpdate = allQuals.filter((q) => !q.fresh && !q.disabled);
    const qualsToDelete = allQuals.filter((q) => q.disabled && !q.fresh);

    if (qualsToDelete.length) {
      const conf = window.confirm(
        "Are you sure you want to delete the removed qualifications?"
      );
      if (!conf) return;
    }

    // Creating new qualifications
    for (const newQual of qualsToCreate) {
      const qualResponse = await this.createQualification({
        ..._.pick(newQual, [
          "qualification_id",
          "qualification_name",
          "qualification_type",
          "contact_person_name",
          "contact_person_type",
          "contact_person_mobile_number",
          "marking_type",
          "marks",
        ]),
        user_id: userId,
      });
      if (!qualResponse) {
        Toast.error("There was an error while submitting data");
        return;
      }
      const addressResponse = await this.createAddress({
        city_town: "",
        pincode: "",
        district: "",
        state: "",
        location: "",
        ...(newQual.address || {}),
        user_id: userId,
        adress_type: "institute",
        country: "India",
        country_iso_code: "IND",
      });
      const documentProofResponse = await this.createIdentityDocument({
        ...newQual.document_proof,
        document_type: DOCUMENT_TYPE.DOCUMENT_PROOF,
        user_id: userId,
      }).then((res) => res[0]);

      await this.attachAddressToQualification({
        addressId: addressResponse.id,
        qualId: qualResponse.id,
      });
      await this.attachIdentityDocumentToQualification({
        qualId: qualResponse.id,
        docId: documentProofResponse.id,
      });
      updateObject(newQual, qualResponse);
      newQual.fresh = undefined;
      newQual.address = {};
      updateObject(newQual.address, addressResponse);
      newQual.document_proof = {};
      updateObject(newQual.document_proof, documentProofResponse);
    }

    // Updating old qualifications
    for (const oldQual of qualsToUpdate) {
      const qualResponse = await this.updateQualification(
        oldQual.id,
        _.pick(oldQual, [
          "qualification_id",
          "qualification_type",
          "institution_name",
          "contact_person_name",
          "contact_person_type",
          "contact_person_mobile_number",
          "marking_type",
          "marks",
        ])
      );

      const addressResponse = await this.updateAddress(oldQual.address);
      const documentPayload = {
        id: oldQual.document_proof.id || null,
        url: oldQual.document_proof.url || null,
        name: oldQual.document_proof.name || null,
      };
      const documentProofResponse = await this.updateIdentityDocument(
        documentPayload
      );
      if (!qualResponse || !addressResponse || !documentProofResponse) {
        Toast.error("There was an error while submitting data");
        return;
      }
    }

    // Deletion of rejected qualifications
    for (const rejectedQual of qualsToDelete) {
      const qualResponse = await this.deleteQualification(rejectedQual.id);
      const addressResponse = await this.deleteAddress(rejectedQual.address.id);
      const docResponse = await this.deleteIdentityDocuments([
        rejectedQual.document_proof.id,
      ]);
      if (!(qualResponse && addressResponse && docResponse)) {
        Toast.error("There was error submitting your form");
      }
    }

    const newAllQuals = [...qualsToCreate, ...qualsToUpdate];
    return newAllQuals;
  }

  // Skills
  fetchUserSkills(userId) {
    return apolloClient
      .query({
        query: FETCH_SKILLS,
        variables: { userId },
      })
      .then(({ data }) => data?.courses_user_skills_details);
  }
  createSkill(values) {
    return apolloClient
      .mutate({
        mutation: INSERT_SKILL,
        variables: { values },
      })
      .then(
        ({ data }) => data?.insert_courses_user_skills_details?.returning[0]
      );
  }
  deleteSkill(id) {
    return apolloClient
      .mutate({
        mutation: DELETE_SKILL_MUTATION,
        variables: { id },
      })
      .then(
        ({ data }) => data.delete_courses_user_skills_details.affected_rows
      );
  }
  async setUserSkills(allSkills, userId) {
    const skillsToCreate = allSkills.filter((q) => !q.disabled && q.fresh);
    const skillsToUpdate = allSkills.filter((q) => !q.fresh && !q.disabled);
    const skillsToDelete = allSkills.filter((q) => q.disabled && !q.fresh);

    if (skillsToDelete.length) {
      const conf = window.confirm(
        "Are you sure you want to delete the removed skills?"
      );
      if (!conf) return;
    }

    // Creating new skills
    for (const newSkill of skillsToCreate) {
      const skillResponse = await this.createSkill({
        ..._.pick(newSkill, ["st_skill_id"]),
        user_id: userId,
      });
      if (!skillResponse) {
        Toast.error("There was an error while submitting data");
        return;
      }

      updateObject(newSkill, skillResponse);
      newSkill.fresh = undefined;
    }

    // Deletion of rejected skills
    for (const rejectedSkill of skillsToDelete) {
      const skillResponse = await this.deleteSkill(rejectedSkill.id);
      if (!skillResponse) {
        Toast.error("There was error submitting your form");
      }
    }

    const newAllSkills = [...skillsToCreate, ...skillsToUpdate];
    return newAllSkills;
  }

  // Languages
  fetchUserLanguages(userId) {
    return apolloClient
      .query({
        query: FETCH_LANGUAGES,
        variables: { userId },
      })
      .then(({ data }) => data?.courses_user_language_details);
  }
  createLanguage(values) {
    return apolloClient
      .mutate({
        mutation: INSERT_LANGUAGE,
        variables: { values },
      })
      .then(
        ({ data }) => data?.insert_courses_user_language_details?.returning[0]
      );
  }
  updateLanguage(languageId, values) {
    return apolloClient
      .mutate({
        mutation: UPDATE_LANGUAGE,
        variables: { languageId, values },
      })
      .then(
        ({ data }) => data.update_courses_user_language_details.returning[0]
      );
  }
  deleteLanguage(id) {
    return apolloClient
      .mutate({
        mutation: DELETE_LANGUAGE_MUTATION,
        variables: { id },
      })
      .then(
        ({ data }) => data.delete_courses_user_language_details.affected_rows
      );
  }
  async setUserLanguages(allLanguages, userId) {
    const languagesToCreate = allLanguages.filter(
      (q) => !q.disabled && q.fresh
    );
    const languagesToUpdate = allLanguages.filter(
      (q) => !q.fresh && !q.disabled
    );
    const languagesToDelete = allLanguages.filter(
      (q) => q.disabled && !q.fresh
    );

    if (languagesToDelete.length) {
      const conf = window.confirm(
        "Are you sure you want to delete the removed skills?"
      );
      if (!conf) return;
    }

    // Creating new languages
    for (const newLanguage of languagesToCreate) {
      const languageResponse = await this.createLanguage({
        ..._.pick(newLanguage, [
          "language_id",
          "can_read",
          "can_speak",
          "can_write",
        ]),
        user_id: userId,
      });
      if (!languageResponse) {
        Toast.error("There was an error while submitting data");
        return;
      }

      updateObject(newLanguage, languageResponse);
      newLanguage.fresh = undefined;
    }

    // Updating old languages
    for (const oldLang of languagesToUpdate) {
      const languageResponse = await this.updateLanguage(
        oldLang.id,
        _.pick(oldLang, ["can_read", "can_speak", "can_write"])
      );

      if (!languageResponse) {
        Toast.error("There was an error while submitting data");
        return;
      }
    }

    // Deletion of rejected languages
    for (const rejectedLanguage of languagesToDelete) {
      const languageResponse = await this.deleteLanguage(rejectedLanguage.id);
      if (!languageResponse) {
        Toast.error("There was error submitting your form");
      }
    }

    const newAllLanguages = [...languagesToCreate, ...languagesToUpdate];
    return newAllLanguages;
  }

  setHighestQualification(qualificationDetails, userId) {
    const { id: highestQualificationId, institutionName } =
      qualificationDetails;
    const requiredQualifications = [];

    for (const qualLevel of SCHOOL_QUALIFICATIONS_HEIRARCHY) {
      let isHighestQualification = false;
      const qual =
        qualLevel.find(
          (q) =>
            Number(q.id) === Number(highestQualificationId) &&
            (isHighestQualification = true)
        ) || qualLevel[0];
      const insertableQual = {};

      Object.assign(insertableQual, {
        qualification_name: qual.name,
        qualification_id: qual.id,
        qualification_type: qual.name,
        user_id: userId,
      });

      if (isHighestQualification) {
        Object.assign(insertableQual, {
          institution_name: institutionName,
        });
      }

      requiredQualifications.push(insertableQual);

      if (isHighestQualification) break;
    }

    return this.createQualification(requiredQualifications.reverse());
  }

  // Work Details
  fetchWorkDetails(userId) {
    return apolloClient
      .query({
        query: FETCH_WORK_DETAILS,
        variables: { userId },
      })
      .then(({ data }) => data.courses_user_work_details);
  }
  updateWorkDetails(values) {
    const { id } = values;
    delete values.id;
    delete values.user_id;
    return apolloClient
      .mutate({
        mutation: UPDATE_WORK_DETAILS,
        variables: {
          workId: id,
          changes: { ...values },
        },
      })
      .then(({ data }) => data.update_courses_user_work_details.returning[0]);
  }
  createWorkDetails(objects) {
    return apolloClient
      .mutate({
        mutation: INSERT_WORK_DETAILS,
        variables: { objects },
      })
      .then(({ data }) => data.insert_courses_work_details.returning[0])
      .catch(console.error);
  }
  createOrUpdateWorkDetails(values) {
    if (values.id) return this.updateWorkDetails(values);
    else return this.createWorkDetails(values);
  }
  deleteWorkDetails(ids) {
    if (ids && ids.length) {
      const ok = window.confirm(
        "Are you sure you want to delete the selected records?"
      );
      if (ok)
        return apolloClient
          .mutate({
            mutation: DELETE_WORK_DETAILS,
            variables: { ids },
          })
          .then(
            ({ data }) => data.delete_courses_user_work_details.affected_rows
          );
    }
  }

  // Email and password
  updateEmail(email) {
    return Axios.post("update_email", { email }).then((res) => {
      if (!res) throw error();
      return res;
    });
  }
  updatePassword(password) {
    return Axios.post("reset_password", {
      password,
      confirm_password: password,
    }).then((res) => {
      if (!res) error();
      return res;
    });
  }

  // Tags
  fetchTags(userId) {
    return apolloClient
      .query({
        query: FETCH_USER_TAGS,
        variables: { userId },
      })
      .then(({ data }) => data.courses_user_tags);
  }
  fetchPrivacyTags(userId) {
    return this.fetchTags(userId).then((tags) =>
      tags.filter((t) => t.name.split(".")[0].toLowerCase() === "privacy")
    );
  }
  createUserTag(values) {
    return apolloClient
      .mutate({
        mutation: INSERT_USER_TAG,
        variables: { values },
      })
      .then(({ data }) => data.insert_courses_user_tags.returning[0]);
  }
  updateUserTag(tagId, values) {
    delete values.id;
    delete values.user_id;
    return apolloClient
      .mutate({
        mutation: UPDATE_USER_TAG,
        variables: { tagId, values },
      })
      .then(({ data }) => data.update_courses_user_tags.returning[0]);
  }
  async createOrUpdateUserTag(values) {
    if (values.id) return this.updateUserTag(values.id, values);
    else {
      const tags = await this.fetchTags(values.user_id);
      const existingTag = tags.find((tag) => tag.name === values.name);
      if (existingTag) return this.updateUserTag(existingTag.id, values);
      else return this.createUserTag(values);
    }
  }

  // Utilities
  sendOtp = (target) =>
    Axios.post(`/send_verification_${target}`).then(({ data }) => data);

  verifyOtp = (otp, medium, services = []) =>
    Axios.post(`/check_verification_otp`, { otp, medium, services }).then(
      ({ data }) => data
    );

  sendRecoveryNotification = (identifier) => {
    return Axios.post(`/forgot_password`, { id: identifier })
      .then(({ data }) => {
        if (data.success)
          Toast.success(
            "Please check your mobile / email for password recovery instructions"
          );
        return data;
      })
      .catch(() =>
        Toast.error("An error occured while sending the recovery notification")
      );
  };
  async checkIsUserOld(identifier) {
    const user = await this.fetchUserDetailsByIdentifier(identifier);
    if (user && user.source === "onboarded") return true;
    return false;
  }
}

const userSvc = new UserService();

export default userSvc;
