import React from "react";
import moment from "moment";
import { useEffect, useState } from "react";
import { PDFExport } from "@progress/kendo-react-pdf";

export default function Certificate(props) {
  const pdfExportComponent = React.useRef(null);

  return (
    <div className="w-full md:h-auto">
      <PDFExport
        // paperSize="auto"
        // portrait={true}
        // margin={40}
        // width="1280"
        // height="800"
        ref={pdfExportComponent}
        fileName={`certificate ${new Date().getFullYear()}`}
        author="skilltrainer.in"
      >
        {" "}
        <div
          className="w-full h-full shadow-md justify-center"
          style={{
            backgroundImage: "url(" + props.certificate.bg_image_url + ")",
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            width: "685px",
            height: "547px",
            margin: "0 auto",
            display: "block",
            // position:'absolute'
          }}
        >
          <div style={{ position: "relative" }}>
            <p
              style={{
                fontSize: parseInt(props.certificate.user_text_size),
                position: "absolute",
                top: parseInt(props.certificate.user_x_axis),
                left: parseInt(props.certificate.user_y_axis),
              }}
              className={`text-black-600 absolute whitespace-nowrap  ${
                props.certificate.user_name_element == false && "hidden"
              }`}
            >
              {props.certificate.user_name}
            </p>
            <p
              style={{
                fontSize: parseInt(props.certificate.course_name_size),
                position: "absolute",
                top: parseInt(props.certificate.course_x_axis),
                left: parseInt(props.certificate.course_y_axis),
              }}
              className={`text-black-600 absolute whitespace-nowrap  ${
                props.certificate.course_name_element == false && "hidden"
              }`}
            >
              {props.certificate?.course_name}
            </p>

            <p
              style={{
                fontSize: parseInt(props.certificate?.grade_size),
                position: "absolute",
                top: parseInt(props.certificate?.grade_x_axis),
                left: parseInt(props.certificate?.grade_y_axis),
              }}
              className={`text-black-600 absolute whitespace-nowrap ${
                props.certificate.grade_element == false && "hidden"
              }`}
            >
              {props.certificate?.grade}
            </p>

            <p
              style={{
                fontSize: parseInt(props.certificate.enroll_size),
                position: "absolute",
                top: parseInt(props.certificate.enroll_x_axis),
                left: parseInt(props.certificate.enroll_y_axis),
              }}
              className={`text-black-600 absolute whitespace-nowrap  ${
                props.certificate.enrollment_id_element == false && "hidden"
              }`}
            >
              {props.certificate.enrollment_id}
            </p>

            {props.certificate.partner_name && (
              <p
                style={{
                  fontSize: parseInt(props.certificate.partner_size),
                  position: "absolute",
                  top: parseInt(props.certificate.partner_x_axis),
                  left: parseInt(props.certificate.partner_y_axis),
                }}
                className={`text-black-600 absolute whitespace-nowrap  ${
                  props.certificate.partner_name_element == false && "hidden"
                }`}
              >
                Partner: {props.certificate?.partner_name}
              </p>
            )}

            {props.certificate.partner_name && (
              <p
                style={{
                  fontSize: parseInt(props.certificate.project_size),
                  position: "absolute",
                  top: parseInt(props.certificate.project_x_axis),
                  left: parseInt(props.certificate.project_y_axis),
                }}
                className={`text-black-600 absolute whitespace-nowrap  ${
                  props.certificate.project_element == false && "hidden"
                }`}
              >
                Project: {props.certificate?.project_name}
              </p>
            )}

            <p
              style={{
                fontSize: parseInt(props.certificate.date_size),
                position: "absolute",
                top: parseInt(props.certificate.date_x_axis),
                left: parseInt(props.certificate.date_y_axis),
              }}
              className={`text-black-600 absolute whitespace-nowrap  ${
                props.certificate.issued_date_element == false && "hidden"
              }`}
            >
              {props.certificate?.issued_date}
            </p>

            <p
              className={`text-black-600 absolute whitespace-nowrap  ${
                props.certificate.nsqf_element == false && "hidden"
              }`}
              style={{
                fontSize: parseInt(props.certificate.nsqf_size),
                position: "absolute",
                top: parseInt(props.certificate.nsqf_x_axis),
                left: parseInt(props.certificate.nsqf_y_axis),
              }}
            >
              {" "}
              {props.certificate?.nsqf}
            </p>
            <p
              className={`text-black-600 absolute whitespace-nowrap  ${
                props.certificate.percentage_element == false && "hidden"
              }`}
              style={{
                fontSize: parseInt(props.certificate.per_size),
                position: "absolute",
                top: parseInt(props.certificate.per_x_axis),
                left: parseInt(props.certificate.per_y_axis),
              }}
            >
              {props.certificate.percentage_grade}
            </p>

            <p
              className={`text-black-600 absolute whitespace-nowrap  ${
                props.certificate.qr_element == false && "hidden"
              }`}
              style={{
                fontSize: parseInt(props.certificate.qr_size),
                position: "absolute",
                top: parseInt(props.certificate.qr_x_axis),
                left: parseInt(props.certificate.qr_y_axis),
              }}
            >
              {/* <QRCode
            title="CertificateVerify"
            value={`https://develop.dbloj6z6ud8hu.amplifyapp.com/certificate/verify/?enrollment_id=${certificate.enrollment_id}&mapping_id=${certificate.mapping_id}`}
            bgColor="#FFFFFF"
            fgColor="#000000"
            size={parseInt(certificate.qr_size)}
          /> */}
            </p>
          </div>
        </div>
      </PDFExport>
    </div>
  );
}
