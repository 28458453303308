import { useEffect, useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_BATCH_USERS } from "../../GraphQl/Queries/BatchSlots";
import { GET_BATCH_SCHEDULES } from "../../GraphQl/Queries/BatchScedules";
import DataTable from "react-data-table-component";
import Container from "../../components/Container";
import moment from "moment";
import ReactSelect, { components } from "react-select";
import Modal from "react-bootstrap/Modal";
import CourseTopics from "../../Pages/Dashboard/users/CourseTopics";
import batchUsersService from "../../services/BatchUsers";
import CourseTopicProgress from "../../Pages/Dashboard/users/CourseTopicProgress";

export default function Trainee({ user }) {
  const [batchUsersLists, setBatchUsers] = useState([]);

  const [batchOPtions, setBatchOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 10,
  });

  const { loading, error, data } = useQuery(GET_BATCH_SCHEDULES, {
    variables: { instructor_id: user?.id },
    fetchPolicy: "network-only",
  });

  const [runQuery, batchUsers] = useLazyQuery(GET_BATCH_USERS, {
    fetchPolicy: "network-only", // Doesn't check cache before making a network request
  });

  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  useEffect(() => {
    if (data && data.courses_course_batches) {
      const graphql_exp = {};

      const batchOptions = data.courses_course_batches?.map((inst, i) => {
        return { value: inst.id, label: inst.batch_name };
      });

      setBatchOptions(batchOptions);

      const batch_ids = data.courses_course_batches?.map((e) => e.id);

      if (batch_ids.length > 0) {
        graphql_exp.id = { _in: batch_ids };
        runQuery({
          variables: graphql_exp,
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (batchUsers.data?.courses_course_batches) {
      setBatchUsers(batchUsers.data?.courses_course_batches);
    }
  }, [batchUsers]);

  const columns = [
    {
      name: "Batch Id",
      selector: (row) => row.id,
      sortable: true,
      width: "7rem",
    },
    {
      name: "Batch Name",
      selector: (row) => row.batch_name,
      sortable: true,
      width: "12rem",
    },
    {
      name: "Start Date",
      selector: (row) => moment(row.from_date).format("YYYY-MM-DD"),
      sortable: true,
      width: "7rem",
    },
    {
      name: "End Date",
      selector: (row) => moment(row.to_date).format("YYYY-MM-DD"),
      sortable: true,
      width: "7rem",
    },

    {
      name: "Course Name",
      selector: (row) => row.course.full_name,
      sortable: true,
      width: "15rem",
    },
  ];

  const handlePageChange = (page) =>
    setPaginationInfo({ ...paginationInfo, page: page });

  const handlePerRowsChange = async (newPerPage, page) =>
    setPaginationInfo({ ...paginationInfo, page: page, per_page: newPerPage });

  const filterTrainee = () => {
    const graphql_exp = {};
    if (selectedValue.length > 0) {
      graphql_exp.in = { _in: selectedValue };
      runQuery({
        variables: graphql_exp,
      });
    }
  };

  return (
    <Container title={"My Trainees"}>
      <div className="mx-3 flex justify-end">
        <div className=" w-1/4">
          <ReactSelect
            options={batchOPtions ? batchOPtions : []}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            onChange={handleChange}
          />
        </div>
        <button
          className="bg-light-orange text-white p-2 rounded-md mx-2"
          onClick={filterTrainee}
        >
          Filter
        </button>
      </div>

      <DataTable
        columns={columns}
        data={batchUsersLists ? batchUsersLists : []}
        expandableRows={true}
        expandableRowsComponent={ExpandedComponent}
        expandOnRowClicked={false}
        expandOnRowDoubleClicked={false}
        expandableRowsHideExpander={false}
        pagination
        paginationServer
        paginationTotalRows={1000}
        // selectableRows
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
    </Container>
  );
}

function ExpandedComponent(props) {
  const [lgShow, setLgShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [completion, setCompletion] = useState([]);
  const [restrict, setRestrict] = useState([]);
  const [course, setCourse] = useState([]);
  const [courseId, setCourseId] = useState("");
  const [userProgressId, setUserId] = useState("");
  const [courseProgress, setCourseProgress] = useState("");
  const [viewTopic, setViewTopic] = useState({
    lessonid: null,
    courseId: null,
  });

  const columns = [
    {
      name: "User Id",
      selector: (row) => row.user.id,
      sortable: true,
      width: "6rem",
    },

    {
      name: "Name",
      selector: (row) => row.user.name,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Email",
      selector: (row) => row.user.email,
      sortable: true,
      width: "14rem",
    },
    {
      name: "Batch Enroll Date",
      selector: (row) => moment(row.batch_enrol_date).format("YYYY-MM-DD"),
      sortable: true,
      width: "12rem",
    },
    {
      cell: (row) => (
        <button
          className="bg-gray-700 p-2 text-base text-white"
          onClick={() => viewProgress(props.data?.course.id, row.user.id)}
        >
          View Progress
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "10rem",
    },
  ];

  const getDifference = (array1, array2) => {
    return array1.filter((object1) => {
      return array2.some((object2) => {
        return object1.mapping_id === object2.mapping_id;
      });
    });
  };

  const viewProgress = async (courseid, userId) => {
    setCourseId(courseid);
    setUserId(userId);
    const res = await batchUsersService.getCourseSection(courseid);
    setCourse(res);

    res &&
      res.map((data) => {
        data.coursesec.map(async (e) => {
          const rescompletion =
            await batchUsersService.checkUserCompletionBySection(
              e.section_id,
              userId,
              e.id
            );

          setCompletion((completion) =>
            completion.concat({
              id: e.id,
              value: rescompletion ? 1 : 0,
            })
          );

          const restriction_ids = await batchUsersService.getModuleRestriction(
            e.id
          );

          if (restriction_ids) {
            const st = await batchUsersService.getModuleRestrictionStatus(
              userId,
              restriction_ids.restriction_ids
            );

            const restIds = restriction_ids.restriction_ids
              .split(",")
              .map(function (strVale) {
                return Number(strVale);
              });

            const elmts = restIds.filter(function (i) {
              return this.indexOf(i) < 0;
            }, st);

            if (elmts.length > 0) {
              setRestrict((restrict) => [...restrict, { id: e.id }]);
            }

            setLgShow(true);
          } else {
            setLgShow(true);
          }
        });
      });

    /**Get CoureProgress */
    const resdata = await batchUsersService.getCourseCompletionTopics(courseid);

    const finallength = resdata.length;

    const userRep = await batchUsersService.GetUserCourseCompletedTopics(
      courseid,
      userId
    );

    const getDiff = getDifference(userRep, resdata);

    let perc = ((finallength - getDiff.length) / finallength) * 100;

    let currentProgress = 100 - perc;
    setCourseProgress(currentProgress);

    /*** */
  };

  const closeModal = () => {
    setLgShow(false);
    setCourseId("");
    setUserId("");
    setCourse([]);
    setViewTopic({
      lessonid: null,
      courseId: null,
    });
    setCompletion([]);
    setRestrict([]);
  };

  const viewTopicFun = (lessonid) => {
    setViewTopic({
      lessonid: lessonid,
      courseId: courseId,
    });
  };

  return (
    <Container>
      <Modal
        size="lg"
        show={lgShow}
        fullscreen={fullscreen}
        onHide={() => closeModal()}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Trainee Course Progress
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex  w-full">
            <div className="w-md hidden xl:block  shadow-xl sticky top-0 left-0 h-screen p-2">
              {course &&
                course.map((data) => (
                  <CourseTopics
                    title={data}
                    module={
                      data.coursesec && data.coursesec.length > 0
                        ? data.coursesec
                        : ""
                    }
                    completion={completion ? completion : ""}
                    // criteria={criteria ? criteria : ""}
                    restrict={restrict}
                    view={courseId}
                    userid={userProgressId}
                    viewTopic={viewTopic}
                    viewTopicFun={(lessonid) => {
                      viewTopicFun(lessonid);
                    }}
                  />
                ))}
            </div>

            <div className="w-full lg:w-10/12 cource_bg p-8">
              <CourseTopicProgress
                userid={userProgressId}
                data={props.data}
                lessonsData={course}
                courseProgress={courseProgress}
                viewTopic={viewTopic}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div class="p-2">
        <h1 class="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
          Batch Trainees
        </h1>
      </div>
      <DataTable
        columns={columns}
        data={props.data?.userEnrollmentBatchArray}
        expandableRows={false}
        expandOnRowClicked={false}
        expandOnRowDoubleClicked={false}
        expandableRowsHideExpander={false}
        pagination
      />
    </Container>
  );
}
