import React, { useState } from "react";
import { Switch } from "react-router-dom";
import Container from "../../../components/Container";
import ToggleList, {
  ToggleListItem,
} from "../../../components/Form/ToggleList";
import { useHistory } from "react-router-dom";
import Navigator from "../../../components/Navigator";
import { AccordionItem } from "../../../components/AccordionItem";
import EmailAndPassword from "./EmailAndPassword";
import CustomRoute from "../../../components/CustomRoute";

export default function Settings(props) {
  const history = useHistory();

  return (
    <>
      <Switch>
        <CustomRoute path={"/account-details"} component={{}} />
        <CustomRoute
          path="/"
          component={(props) => {
            return (
              <Container className="p-4">
                <Navigator
                  back={{ name: "Home", link: "/" }}
                  title="Settings"
                />
                <ToggleList className="mt-10">
                  <AccordionItem
                    title="Profile details"
                    isNotToggleItem
                    onClick={() => history.push("/settings/account-details")}
                  />
                  <ToggleListItem>
                    {(args) => (
                      <AccordionItem {...args} title="Email and password">
                        <EmailAndPassword />
                      </AccordionItem>
                    )}
                  </ToggleListItem>
                  <ToggleListItem>
                    {(args) => (
                      <AccordionItem {...args} title="Education Details">
                        <p>Hello</p>
                      </AccordionItem>
                    )}
                  </ToggleListItem>
                </ToggleList>
              </Container>
            );
          }}
        />
      </Switch>
    </>
  );
}
