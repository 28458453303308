import React, { useState } from "react";
import { UPDATE_TRAINER_BATCH_SLOTS } from "../../../GraphQl/Mutations/Batch";
import { useMutation } from "@apollo/client";
import { generateMeetLink } from "../../../utils/Google";
import { error, wireEventValue } from "../../../utils/func";
import { errorHandler } from "../../../utils/toast";
import GoogleMeetLogo from "../../../assets/Logo/google-calendar.svg";

export default function SetMeetLink(props) {
  const { batchId, onFinish, user } = props;

  const [meetLinkSetupStatus, setMeetLinkSetupStatus] = useState({});

  const [meetLinkInput, setMeetLinkInput] = useState("");

  const [updateBatchBatchSlots] = useMutation(UPDATE_TRAINER_BATCH_SLOTS);
  const setUpMeetingLinkForBatch = async (batchId, method, data) => {
    try {
      setMeetLinkSetupStatus({
        ...meetLinkSetupStatus,
        [batchId]: { started: true },
      });

      let meetLink;
      if (method === "google")
        // Generate meet link
        meetLink = await generateMeetLink().catch((err) => {
          console.error(err);
          error("An error occurred while generating meet link");
        });
      else {
        // Set meet link from user input
        meetLink = data;
      }

      // Update batch slots in DB
      await updateBatchBatchSlots({
        variables: {
          batch_id: batchId,
          instructor_id: user?.id,
          changes: {
            meeting_link: meetLink,
          },
        },
      }).catch(() => {
        error("An error occurred while updating meet link");
      });

      // on finish
      if (typeof onFinish === "function") await onFinish();
    } catch (err) {
      if (typeof onFinish === "function") await onFinish();
      errorHandler(err.msg);
    }

    delete meetLinkSetupStatus[batchId];
    setMeetLinkSetupStatus({ ...meetLinkSetupStatus });
  };

  console.log(meetLinkSetupStatus[batchId]);

  return (
    <div
      className="flex flex-col items-center justify-center px-5"
      style={
        meetLinkSetupStatus[batchId]?.started
          ? {
              opacity: "0.4",
              pointerEvents: "none",
            }
          : {}
      }
    >
      <button
        className="btn-primary flex items-center justify-center gap-2 w-full"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ddd",
          color: "#555",
          paddingLeft: "1.5rem",
          paddingRight: "1.5rem",
        }}
        onClick={() => setUpMeetingLinkForBatch(batchId, "google")}
      >
        <img
          src={GoogleMeetLogo}
          style={{ height: "1.5rem" }}
          alt="Google calendar logo"
        />{" "}
        <span>Create Meet link using Google Calendar</span>
      </button>
      <span className="text-xl font-bold my-4">OR</span>
      <div className="flex items-center gap-2 w-full">
        <input
          type="text"
          className="input-primary w-2/3"
          value={meetLinkInput}
          onChange={wireEventValue(setMeetLinkInput)}
          placeholder="Enter custom meet link"
        />
        <button
          onClick={() =>
            setUpMeetingLinkForBatch(batchId, "manual", meetLinkInput)
          }
          className="btn-primary w-1/3"
        >
          Set custom meet link
        </button>
      </div>
    </div>
  );
}
