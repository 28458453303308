import React, { useState } from "react";
import DataTable from "react-data-table-component";
import ReactSelect from "react-select";
import Container from "../../../components/Container";
import moment from "moment";

export default function BatchSlotsExpandedComponent(props) {
  const today = new Date();

  const [defaultSelect, setDefaultSelect] = useState({
    label: "Upcoming",
    value: "upcoming",
  });

  const UpcomingSlots =
    props.data.batch_slots &&
    props.data.batch_slots.filter(
      ({ slot_date }) => new Date(slot_date) > today
    );

  const CompletedSlots =
    props.data.batch_slots &&
    props.data.batch_slots.filter(
      ({ slot_date }) => new Date(slot_date) < today
    );

  const upcomingDates =
    UpcomingSlots &&
    UpcomingSlots.sort(function (a, b) {
      return new Date(a.slot_date) - new Date(b.slot_date);
    });

  const columns = [
    {
      name: "Start Date & Time",
      selector: (row) => moment(row.slot_date).format("YYYY-MM-DD (hh:mm) A"),
      sortable: true,
      width: "12rem",
    },
    {
      name: "End Date & Time",
      selector: (row) => moment(row.endto_date).format("YYYY-MM-DD (hh:mm) A"),
      sortable: true,
      width: "12rem",
    },
    {
      name: "Day",
      selector: (row) => moment(row.slot_date).format("ddd"),
      sortable: true,
      width: "8rem",
    },
    {
      name: "Meeting Link",
      width: "15rem",
      cell: (row) =>
        row.meeting_link ? (
          <a href={row.meeting_link} target="_blank" rel="noopener noreferrer">
            {row.meeting_link}
          </a>
        ) : (
          <span className="text-gray-500 italic">Meeting link not set up</span>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
    {
      name: "Meeting Status",
      selector: (row) =>
        defaultSelect.value == "upcoming"
          ? "Upcoming"
          : defaultSelect.value == "completed"
          ? "Completed"
          : [],
      sortable: true,
      width: "12rem",
    },
  ];

  const options = [
    // { label: "Ongoing", value: "ongoing" },
    { label: "Upcoming", value: "upcoming" },
    { label: "Completed", value: "completed" },
  ];

  const handleChange = (e) => {
    setDefaultSelect(e);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className=" w-1/4">
        <label>Filter Slots Status</label>
        <ReactSelect
          defaultValue={[defaultSelect && defaultSelect]}
          options={options ? options : []}
          onChange={handleChange}
        />
      </div>
    );
  }, []);

  return (
    <Container>
      <div className="p-2">
        <h1 className="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
          {defaultSelect.label} Batch Slots
        </h1>
      </div>
      <DataTable
        columns={columns}
        data={
          defaultSelect.value == "upcoming"
            ? upcomingDates
            : defaultSelect.value == "completed"
            ? CompletedSlots
            : []
        }
        expandableRows={false}
        expandOnRowClicked={false}
        expandOnRowDoubleClicked={false}
        expandableRowsHideExpander={false}
        pagination
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
      />
    </Container>
  );
}
