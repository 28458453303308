import React, { useEffect, useState } from "react";
import { LogoBlue } from "../../assets";
import { AiOutlineSearch } from "react-icons/ai";
import { useLocation, useRouteMatch } from "react-router";

import { Link } from "react-router-dom";
import Profile from "./components/Profile";
import { useBasePath } from "../../components/useBaseUrl/useBaseUrl";
import { zIndex } from "tailwindcss/defaultTheme";

const Navbar = ({ user }) => {
  const { pathname } = useLocation();
  const basePath = useBasePath();
  const [search, setSearch] = useState("");
  const [nav, setNav] = useState({
    items: [
      // { name: "My Batches", url: "/" },
      // { name: "My Profile", url: "/users" },
      // { name: "Batch Slots", url: "/batchslots" },
      // { name: "Class History", url: "/classhistory" },
      // { name: "My Trainees", url: "/trainee" },
    ],
    current: 0,
  });
  const [profile, setProfile] = useState([
    { name: "Logout", url: "/logout" },
    { name: "Settings", url: "/settings" },
  ]);
  const [profileOpen, setProfileOpen] = useState(false);
  const changeCurrentNav = (index) => {
    setNav({ ...nav, current: index });
  };
  useEffect(() => {
    var changed = false;
    for (var i = 0; i < nav.items.length; i++) {
      if (nav.items[i].url === basePath) {
        changeCurrentNav(i);
        changed = true;
        break;
      }
    }
    if (!changed) changeCurrentNav(0);
  }, [basePath]);

  return (
    <div className="sticky top-0 z-50 bg-gray-300">
      <div className="flex flex-col overflow-hidden shadow-md w-full ">
        <div className="p-3.5 flex justify-between">
          <img src={LogoBlue} className="w-36 md:w-48" />
          {/* <div className="flex">
            <AiOutlineSearch className="mt-2.5 mr-4 text-2xl" />
            <input
              placeholder={"Search"}
              className="placeholder-light-gray outline-none text-gray-500"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div> */}
          <div className="flex mt-3 justify-between">
            {nav.items.map((item, index) => (
              <div className="min-w-max  mx-3" key={item.url}>
                <Link
                  to={item.url}
                  className={`text-base font-semibold text-indigo no-underline h-6 ${
                    nav.current === index && "border-b-2 border-light-orange"
                  }`}
                >
                  {item.name}
                </Link>
              </div>
            ))}
          </div>
          <div className="mt-2">
            <Profile
              username={user?.name}
              open={profileOpen}
              setOpen={setProfileOpen}
            />
          </div>
        </div>
        {profileOpen && (
          <div className="flex-col shadow self-end mr-20 mt-20 rounded-2xl absolute bg-gray-100 w-60 text-center">
            {profile.map((item) => (
              <div className="my-3" key={item.url}>
                <Link
                  to={item.url}
                  className="text-indigo font-medium text-lg inline-block w-10/12 rounded-md mx-1 p-2 hover:bg-gray-300 hover:text-emerald no-underline"
                >
                  {item.name}
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default Navbar;
