import React from "react";
import Routes from "./Routes/Routes";
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  HttpLink,
  from,
  ApolloProvider,
  split,
} from "@apollo/client";
import { createClient } from "graphql-ws";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";

import { getMainDefinition } from "@apollo/client/utilities";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { setContext } from "@apollo/client/link/context";
import { RecoilRoot } from "recoil";

import { onError } from "@apollo/client/link/error";
import { graphqlUri } from "./api/Consts";
import { getJwtToken } from "./utils/Auth";
import { ToastProvider } from "./wrappers/Toast";
import { graphqlWsUri } from "./api/Consts";

// import { ToastContainer } from "react-toastify";

const errorLink = onError(({ graphqlError, newtworkError }) => {
  if (graphqlError) {
    graphqlError.map(({ message, location, path }) => {
      alert(`graphql error ${message}`);
    });
  }
});

const httpLink = createHttpLink({
  uri: graphqlUri,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getJwtToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: graphqlWsUri,
    connectionParams: {
      reconnect: true,
    },
  })
);

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});
const requestLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);
export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(requestLink),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    mutate: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  },
});

const App = () => {
  return (
    <RecoilRoot>
      <ApolloProvider client={client}>
        <>
          <ToastProvider>
            <Routes />
          </ToastProvider>
        </>
        <ToastContainer />
      </ApolloProvider>
      {/* <ToastContainer /> */}
    </RecoilRoot>
  );
};

export default App;
