import React from "react";
import { Link, useLocation } from "react-router-dom";
import { BsFillGridFill, BsFillPersonFill, BsPeopleFill } from "react-icons/bs";

export default function LeftNavbar() {
  const location = useLocation();

  const leftLink = [
    { to: "/", label: "My Batches", icon: <BsFillGridFill size={20} /> },
    { to: "/trainee", label: "My Trainees", icon: <BsPeopleFill size={20} /> },
    {
      to: "/users",
      label: "My Profile",
      icon: <BsFillPersonFill size={20} />,
    },
    {
      to: "/create-trainee",
      label: "Create Trainee",
      icon: <BsPeopleFill size={20} />,
    },
  ];

  return (
    <>
      {leftLink.map((option) => (
        <div
          className={`${
            location.pathname === option.to ? " bg-orange " : ""
          } mt-3 w-full text-white`}
        >
          <Link
            to={option.to}
            className={`${
              location.pathname === option.to
                ? "border-r-4 border-orange text-white"
                : ""
            }  flex items-center cursor-pointer space-x-3 text-gray-400 px-2 py-2 no-underline
             font-medium hover:text-white
              focus:bg-orange-light focus:shadow-outline`}
          >
            {option.icon}
            <span class="font-poppins">{option.label}</span>
          </Link>
        </div>
      ))}
    </>
  );
}
