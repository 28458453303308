import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginPage from "../Pages/Auth/LoginPage";
import DashboardRoutes from "./Dashboard/DashboardRoutes";

import { checkUserAuthentication } from "../api/Auth";

const Routes = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState({});

  const checkLogin = async () => {
    setAuthenticated();
    const LoginResponse = await checkUserAuthentication();

    if (!LoginResponse.success) {
      setAuthenticated(false);
    } else {
      setAuthenticated(true);
      setUser(LoginResponse.data.db_user);
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <Router>
      <Switch>
        <Route
          path="/"
          component={
            authenticated
              ? () => <DashboardRoutes user={user} />
              : () => (
                  <LoginPage
                    setUser={setUser}
                    setAuthenticated={setAuthenticated}
                  />
                )
          }
        />
      </Switch>
    </Router>
  );
};

export default Routes;
