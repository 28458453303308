import { gql } from "@apollo/client";

export const ADDRESS_COLUMNS = `
  id
  city_town
  pincode
  district
  state
  country
  location
  house_number
  country_iso_code
`;

export const USER_COLUMNS = `
  id
  name
  mobile_number
  email
  profile_photo
  source
  gender
  date_of_birth
  social_category
  address_id
  address {
    ${ADDRESS_COLUMNS}
  }
`;

export const IDENTITY_DOCUMENT_COLUMNS = `
  id
  document_type
  user_id
  name
  url
  created_at
  updated_at
  description
`;

export const MEMBER_COLUMNNS = `
  id
  name
  member_type
  occupation
  mobile_number
  email
  annual_income
  is_currently_working
  income_certficate_document_id
  user_id
  created_at
  updated_at
`;

export const QUALIFICATIONS_COLUMNS = `
  id
  qualification_type
  institution_name
  qualification_name
  marks
  marking_type
  institution_address_id
  contact_person_name
  contact_person_type
  contact_person_email
  contact_person_mobile_number
  user_id
  address: user_address {
    ${ADDRESS_COLUMNS}
  }
  achievement
  document_proof_id
  created_at
  updated_at
  document_proof: user_identity_document {
    ${IDENTITY_DOCUMENT_COLUMNS}
  }
  qualification_id
  qualifications_lookup{
    name
    type
    qp_code
  }
`;

export const SKILLS_COLUMNS = `
  id
  st_skill_id
  user_id
  created_at
  updated_at
  skillstrainer_skill{
    name
  }
`;

export const LANGUAGES_COLUMNS = `
  id
  language_id
  user_id
  can_speak
  can_read
  can_write
  created_at
  updated_at
  st_language {
    name
  }
`;

export const WORK_DETAILS_COLUMNS = `
  id
  title
  company_name
  start_date
  end_date
  place
  user_id
  created_at
  updated_at
`;

export const TAG_COLUMNS = `
  id
  name
  value
  user_id
  created_at
`;

export const FETCH_USER_QUERY = gql`
  query ($id: bigint) {
    courses_users(where: { id: { _eq: $id } }) {
      ${USER_COLUMNS}
    }
  }
`;

export const FETCH_IDENTITY_DOCUMENT_QUERY = gql`
  query ($id: bigint!, $docType: String!, $userId: bigint!) {
    courses_user_identity_documents(where: {
      _and: {
        id: { _eq: $id },
        document_type: { _eq: $docType },
        user_id: { _eq: $userId }
      }
    }) {
      ${IDENTITY_DOCUMENT_COLUMNS}
    }
  }
`;

export const FETCH_USER_MEMBERS_QUERY = gql`
  query ($userId: bigint) {
    courses_user_family_details (where: {
      user_id: { _eq: $userId }
    }) {
      ${MEMBER_COLUMNNS}
    }
  }
`;

export const FETCH_USER_MEMBERS_BY_TYPE_QUERY = gql`
  query ($userId: bigint, $memberType: String) {
    courses_user_family_details (where: {
      _and: [
        { user_id: { _eq: $userId }},
        { member_type: { _eq: $memberType }}
      ]
    }) {
      ${MEMBER_COLUMNNS}
    }
  }
`;

export const FETCH_ACADEMIC_QUALIFICATIONS = gql`
  query ($userId: bigint) {
    courses_user_academic_qualifications(
      where: {
        user_id: { _eq: $userId }
      }
    ) {
      ${QUALIFICATIONS_COLUMNS}
    }
  }
`;

// Skill
export const FETCH_SKILLS = gql`
  query ($userId: bigint) {
    courses_user_skills_details(
      where: {
        user_id: { _eq: $userId }
      }
    ) {
      ${SKILLS_COLUMNS}
    }
  }
`;

// Languages
export const FETCH_LANGUAGES = gql`
  query ($userId: bigint) {
    courses_user_language_details(
      where: {
        user_id: { _eq: $userId }
      }
    ) {
      ${LANGUAGES_COLUMNS}
    }
  }
`;

export const FETCH_WORK_DETAILS = gql`
  query ($userId: bigint) {
    courses_user_work_details(
      where: {
        user_id: { _eq: $userId }
      }
    ) {
      ${WORK_DETAILS_COLUMNS}
    }
  }
`;

export const FETCH_USER_TAGS = gql`
  query ($userId: bigint) {
    courses_user_tags (where: {
      user_id: { _eq: $userId }
    }) {
      ${TAG_COLUMNS}
    }
  }
`;
