import { gql } from "@apollo/client";

export const INSERT_GIO_LOCATION = gql`
  mutation insertGioLocation(
    $location_latitude: numeric
    $location_longitude: numeric
    $user_id: bigint
  ) {
    insert_courses_user_location_one(
      object: {
        location_latitude: $location_latitude
        location_longitude: $location_longitude
        user_id: $user_id
      }
    ) {
      location_latitude
      location_longitude
      user_id
    }
  }
`;
