import { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { GENDERS, SCHOOL_QUALIFICATIONS } from "../api/Consts";
import { useQuery } from "@apollo/client";
import { GET_INSTITUTES } from "../GraphQl/Queries/Qualification";

export function TraineeFormBuilder(values) {
  const { institute_pincode: selectedInstitutePincode } = values;
  const [schema, setSchema] = useState({});

  const institutesCriteria = useMemo(() => {
    if (selectedInstitutePincode)
      return {
        pincode: { _eq: selectedInstitutePincode },
      };
    return {};
  }, [selectedInstitutePincode]);

  const { data: institutesRes } = useQuery(GET_INSTITUTES, {
    variables: { institutesCriteria },
  });
  const institutes = institutesRes?.courses_institutes;

  useEffect(() => {
    const schema = {
      full_name: {
        label: "Name",
        type: "text",
        schema: yup.string().min(6, "Name must have atleast 5 characters"),
        required: true,
      },
      email: {
        schema: !values.mobile_number
          ? yup.string().email().required("Enter Email or Mobile number")
          : yup.string().email(),
        label: "Email",
        type: "email",
      },
      mobile_number: {
        label: "Mobile Number",
        type: "text",
        required_or: [1],
        schema: !values.email
          ? yup
              .number()
              .min(10, "Mobile Number should have 10 digits")
              .required("Please Enter Email or Mobile Number")
          : yup.number().min(10, "Mobile Number should have 10 digits"),
      },
      password: {
        label: "Enter password",
        type: "password",
        required: true,
        schema: yup.string(),
      },
      gender: {
        label: "Gender",
        type: "select",
        required: true,
        schema: yup.string(),
        options: GENDERS.map((g) => ({ value: g.id, label: g.name })),
      },
      institute_pincode: {
        label: "Institute pincode",
        schema: yup.string(),
      },
      school_name: {
        label: "Choose school",
        type: "select",
        required: true,
        schema: yup.string(),
        options:
          institutes &&
          institutes.map((i) => ({
            label: i.name,
            value: i.name,
          })),
      },
      qualification_id: {
        label: "Choose standard",
        type: "select",
        required: true,
        schema: yup.string(),
        options: SCHOOL_QUALIFICATIONS.map((q) => ({
          label: q.name,
          value: q.id,
        })),
      },
      coupon_code: {
        label: "Coupon Code",
        type: "text",
        required: true,
        schema: yup.string(),
      },
    };

    setSchema(schema);
  }, [values.email, values.mobile_number, institutes]);

  return schema;
}
