import React, { useEffect, useState } from "react";
import { Form } from "skillstrainer-resource-library";
import UserFormBuilder from "../../form-builders/UserFormBuilder";
import QualificationFormBuilder from "../../form-builders/QualificationFormBuilder";
import WorkExperienceFormBuilder from "../../form-builders/WorkExperienceFormBuilder";
import {
  UPDATE_USER_BY_ID,
  CREATE_USER_MUTATION,
} from "../../GraphQl/Mutations/User";
import { GET_USER_BY_IDP_USER_ID } from "../../GraphQl/Queries/User";
import { useMutation, useLazyQuery } from "@apollo/client";
import { successHandler, errorHandler } from "../../utils/toast";
import userSvc from "../../services/User";
import {
  TECHNICAL_QUALIFICATION_TYPE,
  UPLOAD_COLLECTIONS,
} from "../../api/Consts";
import _ from "lodash";
import { getFile, uploadLargeFile, uploadFile } from "../../api/UploadFile";
import { error } from "../../utils/error";
import { splitByCrudActions } from "../../utils/form";
import Toast from "../../wrappers/Toast";
import usePromise from "../../utils/hooks/usePromise";
function UsersPage(props) {
  const { user } = props;
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [userIdId, setUserId] = useState();
  const [userQualification, setUserQualifications] = useState();
  const [userWorkDetails, setUserWorkdetails] = useState();
  const [update_user] = useMutation(UPDATE_USER_BY_ID);
  const [insertUser, insertUserId] = useMutation(CREATE_USER_MUTATION);

  const [runQuery, user_id] = useLazyQuery(GET_USER_BY_IDP_USER_ID);

  const getWorkDetails = async (userId) => {
    const works = await userSvc.fetchWorkDetails(userId);
    setUserWorkdetails(works);
  };
  const getQualification = async (userId) => {
    const qua = await userSvc.fetchUserQualifications(userId).then((res) =>
      res
        .filter(
          (qual) =>
            Object.values(TECHNICAL_QUALIFICATION_TYPE).indexOf(
              qual.qualification_type
            ) > -1
        )
        .map((qual) => {
          const doc = qual.document_proof;
          return {
            ...qual,
            contact_person: _.pick(qual, [
              "contact_person_type",
              "contact_person_name",
              "contact_person_mobile_number",
            ]),
            // Default value for doc
            doc: doc && doc.url && [_.pick(doc, ["url", "name"])],
          };
        })
    );
    setDataIsLoaded(true);
    setUserQualifications(qua);
  };
  useEffect(() => {
    runQuery({
      variables: {
        idp_user_id: user.idp_user_id,
      },
    }).then((res) => {
      getQualification(res.data.courses_users[0].id);
      getWorkDetails(res.data.courses_users[0].id);
      setUserId(res.data.courses_users[0].id);
    });
  }, []);

  const { run: handleSubmitUploadPhoto, loading: submittingFormUploadPhoto } =
    usePromise(async (userData) => {
      return await update_user({
        variables: {
          idp_user_id: user.idp_user_id,
          trainee_photo_url: userData?.photo[0]?.url,
        },
      })
        .then(() => {
          successHandler("Photo Updated Succesfully");
        })
        .catch((err) => {
          console.log("error==", err);
          errorHandler("There was an error while uploading photo");
        });
    });

  async function qualificationCreation(values, userId) {
    const ref = {};
    if (values.qualifications === undefined) {
      values.qualifications = [];
    }

    ref.docs = userQualification;

    const { qualifications } = values;

    const _quals = qualifications
      .map((qual) => ({
        ...qual,
        ...qual.contact_person,
        fresh: !qual.id,
        document_proof: {
          ...qual.document_proof,
          url: qual.doc && qual.doc[0] && qual.doc[0].url,
          name: qual.doc && qual.doc[0] && qual.doc[0].name,
        },
      }))
      .concat(
        ref.docs
          .filter((oldQual) => !qualifications.find((q) => q.id === oldQual.id))
          .map((q) => ({ ...q, disabled: true }))
      );

    const newQuals = await userSvc
      .setUserQualifications(_quals, userId)
      .then((res) => {
        ref.docs = res;
        successHandler("Success");
      });
    return newQuals;
  }

  const {
    run: handleSubmitQualification,
    loading: submittingFormQualification,
  } = usePromise(async (values) => {
    if (!userIdId) {
      const variable = {
        email: user.email,
        idp_user_id: user.idp_user_id,
        mobile_number: user.mobile_number,
        name: user.name,
        partner_id: user.partner_id,
        active: true,
      };

      return insertUser({ variables: variable })
        .then((res) => {
          successHandler("User Created Successfull");
          qualificationCreation(values, res.data.insert_courses_users_one.id);
        })
        .catch((err) => {
          console.log(err);
          errorHandler("Some error while inserting the qualification");
        });
    } else {
      return qualificationCreation(values, userIdId);
    }
  });
  // const createQualification = async (values) => {};

  const {
    run: handleSubmitWorkExperience,
    loading: submittingFormWorkExperience,
  } = usePromise(async (values) => {
    const ref = {};

    if (values.works === undefined) {
      values.works = [];
    }
    ref.works = userWorkDetails || [];

    const { fresh, same, removed } = splitByCrudActions({
      newRecords: values.works,
      oldRecords: ref.works,
    });

    fresh.forEach((r) => (r.user_id = userIdId));

    if (fresh && fresh.length)
      await userSvc.createWorkDetails(fresh).then(() => {
        successHandler("Success");
      });
    if (removed && removed.length)
      await userSvc.deleteWorkDetails(removed.map((r) => r.id)).then(() => {
        Toast.success("Success");
      });
    for (const record of same) {
      const correspondingOldRecord = ref.works.find((r) => r.id === record.id);
      if (!_.isEqual(record, correspondingOldRecord))
        await userSvc.updateWorkDetails(record).then(() => {
          successHandler("Success");
        });
    }
    ref.works = same.concat(fresh);
  });

  if (!user) {
    return <div></div>;
  }
  const getName = (key) => {
    if (key) {
      return key
        .split("_")
        .join(" ")
        .replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    } else {
      return "";
    }
  };

  return (
    <div className="grid justify-items-center">
      <div className="w-1/3 text-base shadow-lg bg-white rounded-lg">
        {["name", "email", "mobile_number"].map((key) => {
          return (
            <div className="flex flex-wrap  items-stretch">
              <div className="flex items-center flex-row  p-3 w-full gap-3">
                <div className="w-full font-semibold text-center">
                  {getName(key)}
                </div>
                <div className="w-full text-center">
                  {user[key] != "None" ? user[key] : "Not available"}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Form
        formBuilder={UserFormBuilder}
        submitButton={{
          text: submittingFormUploadPhoto ? "submitting..." : "Save",
          className:
            "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
          disabled: submittingFormUploadPhoto,
        }}
        plugins={{
          file: {
            services: {
              uploadFn: (fileData) =>
                uploadFile(fileData, fileData.name, "public-assets", true)
                  .then((res) => res.data_url)
                  .catch(
                    (e) =>
                      console.error(e) ||
                      errorHandler("An error occured while uploading")
                  ),
              getUrl: (args) =>
                getFile(args).then((res) => console.log(res) || res),
            },
          },
        }}
        onSubmit={handleSubmitUploadPhoto}
      />

      <Form
        formBuilder={QualificationFormBuilder}
        submitButton={{
          text: submittingFormQualification ? "submitting..." : "Save",
          className:
            "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
          disabled: submittingFormQualification,
        }}
        key={userQualification && userQualification[0]?.id}
        initValues={{ qualifications: userQualification }}
        onSubmit={handleSubmitQualification}
        plugins={{
          file: {
            services: {
              uploadFn: (fileData) =>
                uploadLargeFile(fileData, fileData.name, true)
                  .then((res) => res.data_url)
                  .catch((e) => {
                    errorHandler("An error occured while uploading");
                    throw error("Couldn't upload file", e.data || e);
                  }),
              getUrl: (args) =>
                getFile(args, true, UPLOAD_COLLECTIONS.assets).then(
                  (res) => console.log(res) || res
                ),
            },
          },
        }}
      />

      <Form
        formBuilder={WorkExperienceFormBuilder}
        key={userWorkDetails && userWorkDetails[0]?.id}
        initValues={{ works: userWorkDetails }}
        submitButton={{
          text: submittingFormWorkExperience ? "submitting..." : "Save",
          className:
            "btn-primary bg-orange text-sm font-semibold text-white rounded-md px-6",
          disabled: submittingFormWorkExperience,
        }}
        onSubmit={handleSubmitWorkExperience}
      />
    </div>
  );
}

export default UsersPage;
