import { getTypenameFromResult } from "@apollo/client/utilities";
import React from "react";
import { useEffect, useState } from "react";
import Container from "../../../components/Container";
import batchUsersService from "../../../services/BatchUsers";
import DataTable from "react-data-table-component";
import moment from "moment";
import { GrAggregate } from "react-icons/gr";
import { PDFExport } from "@progress/kendo-react-pdf";
import QuizProgress from "./Course/QuizProgress";
import ScormProgress from "./Course/ScormProgress";
import Certificate from "./Course/Certificate";
// import QRCode from "qrcode.react";

export default function CourseTopicProgress(props) {
  const queryParams = new URLSearchParams(window.location.search);

  const pdfExportComponent = React.useRef(null);

  const course_id = queryParams.get("courseid");
  const lessonid = queryParams.get("lessonid");
  const [mod, setModType] = useState("");
  const [progress, setProgress] = useState([]);
  const [topicName, setTopicName] = useState("");
  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    per_page: 10,
  });
  const [loading, setLoading] = useState(true);
  const [certificate, setCertificate] = useState({});

  useEffect(() => {
    if (props.viewTopic.lessonid && props.viewTopic.courseId) {
      const lessonid = props.viewTopic.lessonid;

      const getType = async () => {
        const res = await batchUsersService.getCourseMapping(lessonid);

        if (res) {
          setTopicName(res.name);
          const modType = await batchUsersService.checkModType(res.mod_id);
          setModType(modType);

          /***Quiz Progress  */
          if (modType == "quiz") {
            const quizAttempt = await batchUsersService.getUserQuizAttempt(
              lessonid,
              props.userid
            );
            setProgress(quizAttempt);
          }

          if (modType == "certificate") {
            const res = await batchUsersService.getCourseCertificateData(
              lessonid
            );
            setCertificate(() => res);
          }
        }
      };

      setLoading(false);

      getType();
    }
  }, [props.viewTopic]);

  return (
    <Container>
      {loading && (
        <div className="w-full text-center">
          <h2 className="text-4xl text-center font-semibold leading-10 text-japanese_indigo mb-6">
            {props.data.course.full_name}
          </h2>

          <div className="text-3xl mb-6">
            <span className="font-medium mr-3 text-japanese_indigo">
              Total Lessons:
            </span>
            <span>{props.lessonsData && props.lessonsData.length}</span>
          </div>

          <div className="text-3xl mb-6">
            <span className="font-medium mr-3 text-japanese_indigo">
              Course Progress:
            </span>
            <span>{props.courseProgress}%</span>
          </div>
        </div>
      )}
      {!loading && mod == "quiz" && (
        <>
          <div class="p-2">
            <h1 class="font-bold text-center my-4 text-2xl lg:text-4xl text-japanese_indigo">
              {topicName}
            </h1>
          </div>
          <QuizProgress progress={progress} />
        </>
      )}
      {!loading && mod == "scorm-package" && (
        <Certificate certificate={certificate} />
      )}

      {!loading && mod == "certificate" && (
        <p>WORK IN PROGRESS</p>
        // <ScormProgress certificate={certificate} />
      )}
    </Container>
  );
}
