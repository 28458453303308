import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import Container from "../../components/Container";
import { GET_UPCOMING_BATCHES_BY_INSTRUCTOR_ID } from "../../GraphQl/Queries/BatchSlots";
import BatchSlotCard from "./Partner/BatchSlotCard";

export default function BatchSlotsPage({ user }) {
  const { loading, error, data } = useQuery(
    GET_UPCOMING_BATCHES_BY_INSTRUCTOR_ID,
    {
      variables: { instructor_id: user?.id },
    }
  );
  return (
    <>
      <Container title={"Batch Slots"}>
        <h1 className="text-2xl font-bold">Your Upcoming Batches</h1>
        <div className="mx-3">
          <p>Filter by date:</p>
          <input className="mx-2" type="date" name="start-date" id="" />
          <input className="mx-2" type="date" name="end-date" id="" />
          <button className="bg-light-orange text-white p-2 rounded-md mx-2">
            Filter
          </button>
        </div>

        <div className="p-3">
          {data?.courses_batch_slots?.map((item) => {
            return (
              <>
                <BatchSlotCard
                  key={item?.id}
                  batchName={item?.batch?.batch_name}
                  coureName={item?.batch?.course?.full_name}
                  courseImage={item?.batch?.course?.image_url}
                  slotDate={item?.slot_date}
                  slotDays={item?.slot_days}
                  moodleCourseId={item?.batch?.course?.moodle_course_id}
                  courseId={item?.batch?.course_id}
                  platform={item?.platform}
                  meetingUrl={item?.meeting_link}
                  enrolledTrainees={
                    item?.batch?.batch_slots_aggregate?.aggregate?.count
                  }
                  accessToken={user?.access_token}
                />
              </>
            );
          })}
        </div>
      </Container>
    </>
  );
}
