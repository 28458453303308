export const inputDateToISO = (date) => {
  return date;
};

export const splitByCrudActions = ({
  newRecords,
  oldRecords,
  isSame = (oldRecord, newRecord) => oldRecord.id === newRecord.id,
}) => {
  console.log("hey==");
  console.log("jjklgkhlg", oldRecords, newRecords);

  const fresh = newRecords.filter(
    (nr) => !oldRecords.find((or) => isSame(or, nr))
  );
  const same = newRecords.filter((nr) =>
    oldRecords.find((or) => isSame(or, nr))
  );
  const removed = oldRecords.filter(
    (or) => !newRecords.find((nr) => isSame(or, nr))
  );
  return { fresh, same, removed };
};
