import { gql } from "@apollo/client";

export const WORK_DETAILS_COLUMNS = `
  id
  title
  company_name
  start_date
  end_date
  place
  user_id
  created_at
  updated_at
`;

export const GET_USER_BY_IDP_USER_ID = gql`
  query get_users($idp_user_id: String) {
    courses_users(where: { idp_user_id: { _eq: $idp_user_id } }) {
      id
    }
  }
`;

export const GET_USER_BY_IDP_USER_ID_INSTRUCTOR = gql`
  query get_instructor($idp_user_id: String) {
    courses_instructor(where: { idp_user_id: { _eq: $idp_user_id } }) {
      id
      has_allowed_location
    }
  }
`;

export const GET_USER_BY_USERNAME = gql`
  query ($idp_user_names: [String!]!) {
    courses_users(where: { idp_user_name: { _in: $idp_user_names } }) {
      id
    }
  }
`;
