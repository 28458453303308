export default function Container(props) {
  const { title, children } = props;

  return (
    <div className="mx-9 mt-2">
      <div className="text-3xl font-semibold">{title}</div>
      <div className="mt-5">{children}</div>
    </div>
  );
}
