import { error } from "../utils/func";

let gapi = window.gapi;
const API_KEY = "AIzaSyAYHsG3T4x0gp2cZ9bSHyTqZduuNX6X-u0";
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
];
const CLIENT_ID =
  "326855115022-gmnmhcq5if7l9fdqpuf5d7dhacu3762j.apps.googleusercontent.com";
const SCOPES = "https://www.googleapis.com/auth/calendar";

const initClient = new Promise((res, rej) => {
  gapi.load("client:auth2", () => {
    gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      .then(() => res(true))
      .catch(rej);
  });
});

export const checkSignInStatus = async () => {
  try {
    const authInstance = gapi.auth2.getAuthInstance();
    let status = await authInstance.isSignedIn.get();

    return status;
  } catch (error) {
    console.error(error);
  }
};

export const signInToGoogle = async () => {
  try {
    let googleuser = await gapi.auth2
      .getAuthInstance()
      .signIn({ prompt: "consent" });
    if (googleuser) {
      return true;
    }
  } catch (error) {
    console.error(error);
  }
};
window.signInToGoogle = signInToGoogle;

export const signOutFromGoogle = () => {
  try {
    var auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut().then(function () {
      auth2.disconnect();
    });
    return true;
  } catch (error) {
    console.error(error);
  }
};
window.signOutFromGoogle = signOutFromGoogle;

/*
 *
 *
 *
 * Google Meet
 *
 *
 *
 */

export const generateMeetLink = async () => {
  if (!(await initClient)) error("Couldn't create meeting link");

  await signOutFromGoogle();
  await signInToGoogle();

  const event = await createCalendarEvent({
    summary: "Sample calendar event",
    date: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    id: new Date().toISOString(),
  });

  await gapi.client.calendar.events.delete({
    calendarId: "primary",
    eventId: event.id,
  });

  return event.hangoutLink;
};

const createCalendarEvent = async ({ summary, date, id } = {}) => {
  if (!(await initClient.catch(console.error)))
    error("Error loading Google client");

  const isUserSignedIn = await checkSignInStatus();
  if (!isUserSignedIn) await signInToGoogle();

  return await gapi.client.calendar.events
    .insert({
      calendarId: "primary",
      resource: {
        summary,
        location: "skillstrainer.in",
        description: "Skilltrainer Live Classes",

        start: {
          dateTime: date,
          timeZone: "UTC",
        },

        end: {
          dateTime: date,
          timeZone: "UTC",
        },

        conferenceData: {
          createRequest: {
            conferenceSolutionKey: {
              type: "hangoutsMeet",
            },
            requestId: id,
          },
        },
      },
      conferenceDataVersion: 1,
    })
    .then((response) => response.result)
    .catch(console.error);
};

/*
 *
 *
 *
 * Google Maps
 *
 *
 *
 */

export const getCurrentLocation = () =>
  new Promise((res, rej) => {
    navigator.geolocation.getCurrentPosition((position) => res(position), rej);
  });
