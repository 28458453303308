import { gql } from "@apollo/client";

const BATCH_SLOT_COLUMNS = `
  id
  batch_id
  platform
  slots_days
  instructor_id
  meeting_link
  event_id
  updated_at
  created_at
  slot_date
  endto_date
  enable_slots
`;

export const UPDATE_TRAINER_BATCH_SLOTS = gql`
  mutation ($batch_id: Int, $instructor_id: Int, $changes: courses_batch_slots_set_input!) {
    update_courses_batch_slots(
      where: {
        batch_id: { _eq: $batch_id }
        instructor_id: { _eq: $instructor_id }
      }
      _set: $changes
    ) {
      returning {
        ${BATCH_SLOT_COLUMNS}
      }
    }
  }
`;

export const UPDATE_BATCH_ATTENDANCES = gql`
  mutation MyMutation(
    $id: Int
    $attendance_status: Boolean
    $slot_id: Int
    $user_id: Int
  ) {
    update_courses_batch_trainee_attendences(
      where: { slot_id: { _eq: $slot_id }, user_id: { _eq: $user_id } }
      _set: { attendance_status: $attendance_status }
    ) {
      returning {
        user_id
        slot_id
        attendance_status
      }
    }
  }
`;

export const INSERT_BATCH_USER_ATTENDANCE = gql`
  mutation MyMutation(
    $attendance_status: Boolean
    $batch_id: Int
    $slot_id: Int
    $user_id: Int
  ) {
    insert_courses_batch_trainee_attendences(
      objects: {
        attendance_status: $attendance_status
        batch_id: $batch_id
        slot_id: $slot_id
        user_id: $user_id
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPSERT_SKILLSTRAINER_MEDIA = gql`
  mutation insertSkillstrainerMedia(
    $objects: [courses_skillstrainer_media_insert_input!]!
  ) {
    insert_courses_skillstrainer_media(
      objects: $objects
      on_conflict: {
        constraint: skillstrainer_media_pkey
        update_columns: [file_url, file_name, file_type]
      }
    ) {
      returning {
        id
        file_name
      }
      affected_rows
    }
  }
`;

export const INSERT_BATCH_MEDIA_RELATION = gql`
  mutation insertSkillstrainerMedia(
    $objects: [courses_batch_media_relation_insert_input!]!
  ) {
    insert_courses_batch_media_relation(objects: $objects) {
      returning {
        id
        batch_id
        media_id
        remark
        media {
          id
          file_url
          file_name
        }
      }
      affected_rows
    }
  }
`;

export const DELETE_BATCH_MEDIA_RELATION = gql`
  mutation deleteBatchMedia($ids: [bigint!]!) {
    delete_courses_batch_media_relation(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;
