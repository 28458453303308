import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import LeftSideBar from "./LeftSideBar";

const DashboardLayout = ({ user, Component }) => {
  const [sidebar, setSidebar] = useState({
    active: 0,
    items: [],
  });

  return (
    <>
      <Navbar user={user} />
      <div className="flex flex-wrap w-full">
        <div className="w-2/12 ">
          <LeftSideBar user={user} sidebar={sidebar} />
        </div>
        <div className="w-10/12 cource_bg ">
          <Component user={user} setSidebar={setSidebar} sidebar={sidebar} />
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
