import { gql } from "@apollo/client";

export const GET_UPCOMING_BATCHES_BY_INSTRUCTOR_ID = gql`
  query getTrainerBatchesSlots($instructor_id: bigint = "") {
    courses_batch_slots(
      where: { instructor: { id: { _eq: $instructor_id } } }
      order_by: { slot_date: asc }
    ) {
      meeting_link
      platform
      slot_date
      slots_days
      id
      batch {
        batch_name
        course {
          image_url
          full_name
          moodle_course_id
        }
        batch_slots_aggregate {
          aggregate {
            count
          }
        }
        course_id
      }
    }
  }
`;

export const GET_BATCH_USERS = gql`
  query MyQuery($id: Int_comparison_exp = {}) {
    courses_course_batches(where: { id: $id }) {
      id
      from_time
      repeat_end_time
      batch_name
      userEnrollmentBatchArray {
        user {
          email
          name
          id
        }
        batch_enrol_date
        course_id
      }
      course {
        full_name
        id
      }
    }
  }
`;

export const GET_BATCH_MEDIA_BY_ID = gql`
  query getBatchMedia($batchId: bigint) {
    courses_batch_media_relation(where: { batch_id: { _eq: $batchId } }) {
      id
      batch_id
      media_id
      remark
      media {
        id
        file_url
        file_name
      }
    }
  }
`;
