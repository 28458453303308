import React, { useEffect, useMemo, useState } from "react";
import Container from "../../components/Container";
import DataTable from "react-data-table-component";

import { GET_BATCH_SCHEDULES } from "../../GraphQl/Queries/BatchScedules";
import { useQuery } from "@apollo/client";
import moment from "moment";

import TraineeAttendance from "./users/TraineeAttendance";
import Modal from "react-bootstrap/Modal";
import batchUsersService from "../../services/BatchUsers";
import BatchSlotsExpandedComponent from "./DashboardPage/BatchSlotsExpandedComponent";

import SetMeetLink from "./DashboardPage/SetMeetLink";

import _ from "lodash";
import UploadMediaModal from "./users/UploadMediaModal";
import { asdmCourseReportConfig } from "../../api/Consts";
import GeoLocation from "./GeoLocation";

const DashboardPage = ({ user, setSidebar }) => {
  useEffect(() => {
    setSidebar({ items: [], active: 0 });
  }, []);

  const { data: batchSchedulesRes, refetch: refetchBatchSchedules } = useQuery(
    GET_BATCH_SCHEDULES,
    {
      variables: { instructor_id: user?.id || -1 },
      fetchPolicy: "network-only", // Doesn't check cache before making a network request
    }
  );
  const [showTable, setShowTable] = useState({
    currentBatches: true,
    upcomingBatches: false,
    completedBatches: false,
  });

  const batchLists = batchSchedulesRes?.courses_course_batches;
  const currentDate = moment(Date.now()).format();

  console.log(batchLists);

  const hasASDMBatch = useMemo(
    () =>
      batchLists &&
      batchLists.find(
        (batch) => batch.project_id === asdmCourseReportConfig.projectId
      ),
    [batchLists]
  );

  useEffect(() => {
    setSidebar({ items: [], active: 0 });
  }, []);

  const columns = [
    {
      name: "Batch Id",
      width: "7rem",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Batch Name",
      selector: (row) => row.batch_name,
      sortable: true,
      width: "12rem",
    },
    {
      name: "Course Name",
      selector: (row) => row.course.full_name,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Batch Slots",
      width: "6rem",
      selector: (row) => row.batch_slots.length,
      sortable: true,
    },
    {
      name: "Start date",
      width: "10rem",
      selector: (row) => moment(row.from_date).format("DD MMM, YY"),
      sortable: true,
    },
    {
      name: "End date",
      width: "10rem",
      selector: (row) => moment(row.to_date).format("DD MMM, YY"),
      sortable: true,
    },
    {
      name: "Is demo",
      width: "6rem",
      selector: (row) =>
        row.is_demo ? (
          <span className="text-green-600 font-semibold">Yes</span>
        ) : (
          <span className="text-gray-500 font-semibold">No</span>
        ),
    },
    {
      name: "Actions",
      selector: (batch) => {
        const batchSlotWithMeetingLink = batch.batch_slots.find(
          (bs) => bs.instructor_id === user?.id && bs.meeting_link
        );
        return (
          <div className="flex gap-2">
            {batchSlotWithMeetingLink && (
              <a href={batchSlotWithMeetingLink.meeting_link} target="_blank">
                <button className="btn-primary">
                  Go to upcoming batch slot
                </button>
              </a>
            )}

            {batch.type === "online" && (
              <button
                className="btn-primary"
                onClick={() => {
                  setSelectedBatch(batch);
                  setIsSetMeetLinkModalOpen(true);
                }}
              >
                {batchSlotWithMeetingLink ? "Edit" : "Create"} meeting link
              </button>
            )}

            <button
              className="btn-primary"
              onClick={async () => {
                setSelectedBatch(batch);
                await fetchAttendance(batch.id);
                setIsBatchAttendanceOpen(true);
              }}
            >
              View Attendance{" "}
            </button>

            <button
              className="btn-secondary"
              onClick={async () => {
                setSelectedBatch(batch);
                setIsBatchMediaOpen(true);
              }}
            >
              Upload Media{" "}
            </button>
          </div>
        );
      },
    },
  ];

  const [selectedBatch, setSelectedBatch] = useState({});

  /*
   *
   *
   *
   * Set up meet link
   *
   *
   *
   */
  const [isSetMeetLinkModalOpen, setIsSetMeetLinkModalOpen] = useState();
  const onSetMeetLink = async () => {
    await refetchBatchSchedules().catch(() => window.location.reload());
    setIsSetMeetLinkModalOpen(false);
  };

  /*
   *
   *
   *
   * View attendance
   *
   *
   *
   */

  const [isBatchAttendanceOpen, setIsBatchAttendanceOpen] = useState();
  const [selectedBatchAttendance, setSelectedBatchAttendance] = useState({
    batch_slots: [],
    users: [],
  });
  const fetchAttendance = async (batchid) => {
    const batch = batchLists && batchLists.find((e) => e.id == batchid);
    const { batch_slots, userEnrollmentBatchArray } = batch;

    const attendanceRecords = await batchUsersService.getBatchAttendance(
      batchid
    );

    let userAttendance;
    if (!batch.is_demo) {
      // For non demo batch
      userAttendance = userEnrollmentBatchArray.reduce((users, { user }) => {
        users[user.id] = {
          user,
          attendance: attendanceRecords
            .filter((ar) => ar.user_id === user.id)
            .map((e) => ({ ...e, status: e.attendance_status })),
        };
        return users;
      }, {});
    } else {
      // For demo batch
      userAttendance = attendanceRecords.reduce((users, attendanceRecord) => {
        if (!users[attendanceRecord.user_id])
          users[attendanceRecord.user_id] = {
            user: attendanceRecord.user,
            attendance: [],
          };
        users[attendanceRecord.user_id].attendance.push({
          ...attendanceRecord,
          status: attendanceRecord.attendance_status,
        });

        return users;
      }, {});
    }

    for (const uaRecord of Object.values(userAttendance)) {
      uaRecord.attendance = uaRecord.attendance.sort(
        (a, b) => new Date(a.slot.slot_date) - new Date(b.slot.slot_date)
      );
    }

    setSelectedBatchAttendance({
      batch_slots,
      users: Object.values(userAttendance),
    });
  };
  /**
   *
   *
   * media upload
   *
   *
   */

  const [isBatchMediaOpen, setIsBatchMediaOpen] = useState(false);

  return (
    <Container title={"Dashboard"}>
      {hasASDMBatch && (
        <div className="mb-4">
          <GeoLocation user={user} />
        </div>
      )}
      <div className="flex flex-row mb-4">
        <button
          className={` mr-2 ${
            showTable.currentBatches ? "btn-secondary" : "btn-primary"
          }`}
          onClick={() =>
            setShowTable({
              ...showTable,
              currentBatches: !showTable.currentBatches,
            })
          }
        >
          {showTable.currentBatches
            ? "Hide Current Batches"
            : "Show Current Batches"}
        </button>
        <button
          className={` mx-2 ${
            showTable.upcomingBatches ? "btn-secondary" : "btn-primary"
          }`}
          onClick={() =>
            setShowTable({
              ...showTable,
              upcomingBatches: !showTable.upcomingBatches,
            })
          }
        >
          {showTable.upcomingBatches
            ? "Hide Upcoming Batches"
            : "Show Upcoming Batches"}
        </button>
        <button
          className={` mx-2 ${
            showTable.completedBatches ? "btn-secondary" : "btn-primary"
          }`}
          onClick={() =>
            setShowTable({
              ...showTable,
              completedBatches: !showTable.completedBatches,
            })
          }
        >
          {showTable.completedBatches
            ? "Hide Completed Batches"
            : "Show Completed Batches"}
        </button>
      </div>

      {showTable.currentBatches && (
        <DataTable
          title="Current Batches"
          columns={columns}
          data={(batchLists || []).filter(
            (batch) =>
              batch?.from_date < currentDate && batch?.to_date > currentDate
          )}
          expandableRows={true}
          expandableRowsComponent={BatchSlotsExpandedComponent}
          expandOnRowClicked={false}
          expandOnRowDoubleClicked={false}
          expandableRowsHideExpander={false}
          pagination
        />
      )}

      {showTable.upcomingBatches && (
        <DataTable
          title="Upcoming Batches"
          columns={columns}
          data={(batchLists || []).filter(
            (batch) => batch?.from_date > currentDate
          )}
          expandableRows={true}
          expandableRowsComponent={BatchSlotsExpandedComponent}
          expandOnRowClicked={false}
          expandOnRowDoubleClicked={false}
          expandableRowsHideExpander={false}
          pagination
        />
      )}

      {showTable.completedBatches && (
        <DataTable
          title="Completed Batches"
          columns={columns}
          data={(batchLists || []).filter(
            (batch) => batch?.to_date < currentDate
          )}
          expandableRows={true}
          expandableRowsComponent={BatchSlotsExpandedComponent}
          expandOnRowClicked={false}
          expandOnRowDoubleClicked={false}
          expandableRowsHideExpander={false}
          pagination
        />
      )}
      {/* Set meet link modal */}
      <Modal
        size="lg"
        show={isSetMeetLinkModalOpen}
        onHide={() => setIsSetMeetLinkModalOpen(false)}
      >
        <Modal.Header closeButton>Set batch meet link</Modal.Header>
        <Modal.Body>
          <SetMeetLink
            onFinish={onSetMeetLink}
            batchId={selectedBatch?.id}
            user={user}
          />
        </Modal.Body>
      </Modal>

      {/* Attendance modal */}
      <Modal
        size="lg"
        show={isBatchAttendanceOpen}
        onHide={() => setIsBatchAttendanceOpen(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <TraineeAttendance
            key={selectedBatch?.id}
            batch={selectedBatch}
            userAttendance={selectedBatchAttendance?.users || []}
            batchSlots={selectedBatchAttendance?.batch_slots || []}
            refetchAttendance={() => fetchAttendance(selectedBatch.id)}
          />
        </Modal.Body>
      </Modal>

      {/* media modal */}
      <Modal
        size="lg"
        show={isBatchMediaOpen}
        onHide={() => setIsBatchMediaOpen(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <UploadMediaModal key={selectedBatch?.id} batch={selectedBatch} />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default DashboardPage;
