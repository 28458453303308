import { toast } from "react-toastify";

export const successHandler = (successMessage) => {
  successMessage = successMessage || "Success!";
  toast.success(successMessage, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    style: { fontFamily: "Poppins" },
  });
};

export const errorHandler = (errorMessage) => {
  errorMessage =
    (errorMessage && typeof errorMessage === "object" && errorMessage.msg) ||
    errorMessage;
  toast.error(errorMessage, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    style: { fontFamily: "Poppins" },
  });
};
