import React from "react";
import { useLocation } from "react-router";
import { useHistory, useRouteMatch } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa"; //react-icon
import Container from "../../../components/Container";
import Collapsible from "react-collapsible";
import { Link, NavLink } from "react-router-dom";

export default function CourseTopics(props) {
  const queryParams = new URLSearchParams(window.location.search);
  const lessonid = queryParams.get("lessonid");

  let lessons =
    props.module &&
    props.module.map((e, i) => {
      let result =
        props.completion &&
        props.completion.find((element) => element.id === e.id);

      if (result) {
        return { ...e, completion: result.value };
      }
    });

  return (
    <Container>
      <Collapsible
        open={true}
        lazyRender={true}
        trigger={[
          props.title.name,
          <FaAngleRight
            size={20}
            className="absolute right-1 bottom-3 text-black lesson-arow"
          />,
        ]}
        contentHiddenWhenClosed={false}
        transitionTime={300}
        tabIndex={0}
      >
        {lessons &&
          lessons.map((data) => {
            if (data) {
              const st =
                props.restrict.length > 0 &&
                props.restrict.some((c) => c.id == data.id);

              return (
                <div
                  key={data.id}
                  className={`flex cursor-pointer ${
                    st ? "border-red-500 border-dotted cursor-not-allowed" : ""
                  }`}
                  onClick={() => (!st ? props.viewTopicFun(data.id) : "")}
                >
                  <div
                    key={data.id}
                    className={` w-full text-left border p-2 rounded-md mt-2 text-black ${
                      props.viewTopic.lessonid == data.id
                        ? " bg-orange  text-white"
                        : ""
                    } `}
                  >
                    {data.name}

                    <input
                      type="checkbox"
                      defaultChecked={data.completion === 1 ? true : false}
                      name="completion"
                      className="float-right	pl-2"
                      disabled={true}
                    />
                  </div>
                </div>
              );
            }
          })}
      </Collapsible>
    </Container>
  );
}
